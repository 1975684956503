import { Call } from '@mui/icons-material'
import { Box, Card, CardContent, Typography, styled, Button, Divider, Grid, Avatar, Rating, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { sampleSize } from 'lodash'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import base from '../api/apis'



const FloatCard = styled(Card)(({theme, position, top, right, width}) => ({
    zIndex: '99',
	position: position,
    width: width,
    padding: '2rem',
	top: top,
	right: right,
    borderRadius: '5px',
    boxShadow: '0 1px 5px rgba(0,0,0, 20%)',
    borderTop: `5px solid ${theme.palette.secondary.main}`
}))


const FeaturedAgent = ({ image, name, phone, rating, id }) => {
	const navigate = useNavigate();
	return (
		<>
			<Box
				display={'flex'}
				justifyContent='flex-start'
				alignItems={'center'}
				gap='1rem'
                my={3}
				sx={{ cursor: 'pointer' }}
				onClick={() => navigate(`/agents/${id}`)}
			>
				<Avatar src={image} sx={{
                    width: (theme) => theme.spacing(9),
		            height: (theme) => theme.spacing(9),
                    }} />
				<Box>
					<Typography sx={{fontSize: '1rem'}} noWrap>{name}</Typography>
					<Rating size='small' value={rating} readOnly precision={0.5} />
					<ListItem disablePadding>
						<ListItemIcon sx={{ minWidth: '30px' }}>
							<Call sx={{fontSize: '1rem'}} />
						</ListItemIcon>
						<ListItemText>
							<Typography variant='body2' sx={{fontSize: '.9rem'}} color={'textSecondary'}>{phone}</Typography>
						</ListItemText>
					</ListItem>
				</Box>
			</Box>
		</>
	);
};


const FloatingBar = ({position, top, right, width, startRequest, city, disable}) => {
    const [featuredAgents, setFeaturedAgents] = useState(null);

    const fetchAgents = async () => {
		try {
			const res = await base.get(`/register/search_realtyagent/?query=${city}`);
            // console.log(res.data)
			//sample featuredAgents
			setFeaturedAgents((state) => {
				const agents = sampleSize(res.data.results, 2);
                // console.log(res.data.results)
				return agents;
			});

			//setData(agents.results);
			//setPageCount(agents.count);
		} catch (error) {}
	};

    useEffect(() => {
		fetchAgents();
		// eslint-disable-next-line 
	}, []);

   
	

  return (
    <>
    <FloatCard position={position} top={top} right={right} width={width}>
        <Box>
            <Typography variant='h6' textAlign={'center'} mb={1} sx={{lineHeight: 1.2}}>Schedule a Tour <br/>with a BPO Homes Agent.</Typography>
            <Typography textAlign={'center'} sx={{fontSize: '.8rem'}} variant='body2' color={'textSecondary'}  mb={1}>Its free with no obligation - cancel anytime</Typography>
            <Button size='large' disabled={disable} onClick={startRequest} sx={{mb: '2rem', mt: '5px', borderRadius: '10px'}} variant='contained' disableElevation fullWidth color='secondary'>Request A Tour</Button>
        </Box>
        <CardContent sx={{padding: 0}}>
            <Divider><Typography>BPO Homes Agents</Typography></Divider>
                { 
                    featuredAgents?.map((el) => {
							return (
								<Grid item xs={12} sm={6} md={4} lg={4} key={el.id}>
									<FeaturedAgent
										id={el.id}
										name={el.user.first_name.toUpperCase() + ' ' + el.user.last_name.toUpperCase()}
										phone={el.phone}
										image={el.profile_image}
										rating={5}
									/>
								</Grid>
							);
                	})
                }
            <Divider sx={{mb: 2}}><Typography>Have an offer in mind?</Typography></Divider>
        </CardContent>
        <Box>
            <Button size='large' disabled={disable} onClick={startRequest} sx={{mb: '2rem', mt: '5px', borderRadius: '10px'}} variant='contained' disableElevation fullWidth color='secondary'>Start an Offer</Button>
        </Box>
    </FloatCard>
        
    </>
  )
}

export default FloatingBar