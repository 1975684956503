import { Favorite, FavoriteBorder, KingBed, Shower, SquareFoot } from '@mui/icons-material'
import { Box, Card, CardContent, Chip, IconButton, Typography, styled, Tooltip, CircularProgress, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { addToFavorite, removeFavorite } from '../actions'
import { getOpenHouseTime } from '../utils/functions'
import _ from 'lodash'



const StyledCard = styled(Card)(({ theme, border, inView }) => ({
    boxShadow: '0 1px 10px rgba(0,0,0, 12%)',
    borderRadius: '10px',
    border: border ? `1px solid ${theme.secondaryColor}` : `1px solid #fff`,
    transition: 'all .2s ease',
    '&:hover': {
        border: `1px solid ${theme.secondaryColor}`,
        cursor: 'pointer',
        '& #lb': {
            background: theme.palette.primary.main,
            color: '#000',
            fontWeight: 500
        }
    }

}))

const ImageBox = styled(Box)(({ theme, image }) => ({
    height: '12rem',
    backgroundImage: `url(${image})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: '1.5rem .8rem',

}))


const ListingCard = (props) => {
    const { id, inView, price, image, bed, bath, area, address, mlsId, border, addToFavorite, removeFavorite, listDate, vendor, status, startTime, endTime, active, office } = props
    const currentUser = useSelector(state => state.currentUser)
    const dispatch = useDispatch()
    const [load, setLoad] = useState(false)
    const [isFav, setIsFav] = useState(false)


    useEffect(() => {
        if (currentUser) {
            //console.log(currentUser)
            const res = currentUser?.favs.filter(el => {
                return el.property_id === mlsId
            }).length > 0
            setIsFav(res)
        } else {
            setIsFav(false)
        }
    }, [currentUser, mlsId])

    const addToFav = async (e) => {
        e.stopPropagation()
        if (currentUser) {
            if (currentUser.favs.filter(el => el.property_id === mlsId)[0]) {
                //remove from favs
                setLoad(true)
                const status = await removeFavorite(mlsId)
                if (status === 204) {
                    setLoad(false)
                    dispatch({ type: 'SUCCESS', payload: 'Property removed from favorites' })
                } else {
                    setLoad(false)
                    dispatch({ type: 'SUCCESS', payload: 'Sorry, could not add to favorite' })
                }
            } else {
                //add to favs
                setLoad(true)

                const data = {
                    user_id: currentUser.id,
                    property_id: mlsId,
                    address: address,
                    price: Number(price.split(',').join('')),
                    image: image,
                    bedrooms: bed,
                    baths: bath,
                    area: area,
                    list_date: new Date(listDate)
                }
                //console.log(data)
                const status = await addToFavorite(data)
                if (status === 200) {
                    setLoad(false)
                    dispatch({ type: 'SUCCESS', payload: 'Property added to favorites' })
                } else {
                    setLoad(false)
                    dispatch({ type: 'ERROR', payload: 'Sorry, could not add to favorite' })
                }
            }
        } else {
            dispatch({ type: 'AUTH_LOGIN' })
        }
    }

    const getDaysOnMarket = (listDate) => {
        if (listDate) {
            const diff = new Date(Date.now()).getTime() - new Date(listDate).getTime()
            const dy = Math.round(diff / (1000 * 3600 * 24))
            if (dy <= 30) {
                return 'New on BPO Homes'
            } else {
                return dy
            }
        }
    }

    return (
        <>

            <StyledCard id={id} style={{ border: inView ? '2px solid #1378A5' : 'none' }} onClick={() => {
                status === 'openhouse' ?
                    window.open(`/open-houses/${mlsId}/details/${vendor}`, '_blank')
                    : status === 'soldhomes' ?
                        window.open(`/sold-homes/${mlsId}/details/${vendor}`, '_blank')
                        : status === 'rental' ?
                            window.open(`/rental/${mlsId}/details/${vendor}`, '_blank')
                            :
                            window.open(`/listing/${mlsId}/details/${vendor}`, '_blank')
            }} border={border}>
                <ImageBox image={image}>
                    {
                        status === 'openhouse' ?
                            <Chip component={'span'}
                                sx={{
                                    marginLeft: '5px',
                                    bgcolor: 'primary.main',
                                    boxShadow: '0 1px 5px rgba(0,0,0, 20%)',
                                    transition: 'all .2s ease'
                                }} size='small' label={<Typography sx={{ fontSize: '.7rem', fontWeight: 400 }}>{getOpenHouseTime(startTime, endTime)}</Typography>} />
                            :
                            <>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    {
                                        getDaysOnMarket(listDate) >= 30 || (active === 'Sold' || active === 'Closed') ? null
                                            :
                                            <Chip component={'span'} sx={{ marginLeft: '5px', bgcolor: 'primary.main', boxShadow: '0 1px 5px rgba(0,0,0, 20%)', transition: 'all .2s ease' }} size='small' label={getDaysOnMarket(listDate)} />
                                    }
                                    {
                                        active === 'Sold' || active === 'Closed' ?
                                            <Chip component={'span'} size='small' sx={{ color: '#fff', marginLeft: 'auto', bgcolor: 'secondary.main', boxShadow: '0 1px 5px rgba(0,0,0, 20%)', transition: 'all .2s ease' }}
                                                label={<Typography sx={{ fontSize: '.8rem', fontWeight: 500 }}>{`${'SOLD ' + new Date(listDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })?.toUpperCase()}`}</Typography>}

                                            />
                                            :
                                            <Chip component={'span'} size='small' sx={{ marginLeft: 'auto', bgcolor: '#fff', boxShadow: '0 1px 5px rgba(0,0,0, 20%)', transition: 'all .2s ease' }}
                                                label={<Typography sx={{ fontSize: '.8rem' }}>{active === 'PendingDoNotShow' ? 'Pending' : active === '' ? 'Pending' : _.startCase(active)}</Typography>}

                                            />

                                    }
                                </Stack>
                            </>
                    }
                </ImageBox>
                <CardContent sx={{ padding: '1rem 2rem' }}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Stack direction={'row'} gap={'.5rem'} alignItems={'center'}>
                            <Typography component={'p'} variant='h6' sx={{ fontWeight: 600, fontSize: '1.2rem' }}>${price}</Typography>
                            {
                                (active === 'Sold' || active === 'Closed') && <Typography variant='body2' sx={{ fontSize: '.9rem' }} color={'GrayText'}>Sold Price</Typography>
                            }
                        </Stack>
                        {
                            active === 'Sold' || active === 'Closed' ? null
                                :
                                <IconButton disableTouchRipple onClick={addToFav}>
                                    {load ? <CircularProgress size='1.33rem' color='secondary' />
                                        : isFav ? <Favorite color='secondary' fontSize='small' /> : <FavoriteBorder color='secondary' fontSize='small' />
                                    }
                                </IconButton>
                        }
                    </Box>
                    <Box display={'flex'} gap={'.8rem'}>
                        <Box sx={{ display: 'flex', gap: '.2rem' }}>
                            <Tooltip title='Bedroom' arrow>
                                <KingBed sx={{ fontSize: '1.4rem' }} color='secondary' />
                            </Tooltip>
                            <Typography variant='body2' color={'textPrimary'}>{bed}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '.2rem' }}>
                            <Tooltip title='Bathroom' arrow>
                                <Shower sx={{ fontSize: '1.4rem' }} color='secondary' />
                            </Tooltip>
                            <Typography variant='body2' color={'textPrimary'}>{bath}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '0rem' }}>
                            <Tooltip title='Square Ft' arrow>
                                <SquareFoot sx={{ fontSize: '1.4rem' }} color='secondary' />
                            </Tooltip>
                            <Typography variant='body2' color={'textPrimary'}>{area} Sqft</Typography>
                        </Box>
                    </Box>
                    <Typography component={'h3'} mt={1} sx={{ fontSize: '.9rem' }} noWrap color={'textPrimary'}>{address}</Typography>
                    {
                        (active === 'Sold' || active === 'Closed') && <Typography component={'p'} mt={.5} sx={{ fontSize: '.8rem' }} noWrap color={'textSecondary'}>Listing by {office?.name}</Typography>
                    }
                </CardContent>
            </StyledCard>

        </>
    )
}

const mapStateToprops = (state) => {
    return state
}

export default connect(mapStateToprops, { addToFavorite, removeFavorite })(ListingCard)