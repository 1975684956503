import { Circle, FavoriteBorder, Share, KeyboardArrowRight, Add, Close, Favorite, CheckCircle, Error, DirectionsCar, DirectionsBikeOutlined, DirectionsRailwayFilledOutlined, DirectionsRun, Delete, NearMe  } from '@mui/icons-material'
import { Avatar, Box, Button, Chip, Container, Dialog, DialogContent, Divider, Grid, IconButton, ImageList, ImageListItem, ListItem, ListItemIcon, ListItemText, styled, Tab, Typography, Table, TableBody, TableCell, TableHead, TableRow, TextField, InputAdornment, Hidden, CircularProgress, CardMedia, ButtonGroup, MenuItem  } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Stack, alpha } from '@mui/system'
import { exteriorDetails, garageDetails, interiorDetails } from '../constants'
import _ from 'lodash'
import { useEffect, useReducer, useRef, useState } from 'react'
import FloatingBar from '../components/FloatingBar'
import { useLocation, useParams } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import Loader from '../components/Loader'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import ListingCard from '../components/ListingCard'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from 'react-share'
import { connect, useDispatch, useSelector } from 'react-redux'
import { addToFavorite, filterListingsWithoutPhotos, filterOpenListingsWithoutPhotos, removeFavorite } from '../actions'
import HomeIMG from '../assets/home.png'
import base, { listingURL } from '../api/apis'
import { crmlsOpenHouseTime, formatPhoneNumber, getAllDaysInMonth, getOpenHouseTime } from '../utils/functions'
import { GoogleMap, Marker, Autocomplete, useJsApiLoader, DirectionsRenderer } from '@react-google-maps/api';
import MarkerIcon from '../assets/rect3.svg'
import MarkedIcon from '../assets/rect4.svg'
import HouseMarker from '../assets/house2.svg'
import NoProperty from '../assets/homesearch.png'
import { timeData } from '../constants'
import { convertCurrencySystem } from '../constants/functions'
import SEO from '../components/SEO'
import DoughnutChart from '../components/Charts/Doughnut'


const previewHeight = '60vh'

const IconBtn = styled(IconButton)(({theme})=> ({
    background: '#fff',
    boxShadow: '0 2px 5px rgba(0,0,0,12%)',
    '&:hover': {
        background: theme.palette.secondary.main,
        color: '#fff'
    }
}))

const TitleHead = styled(Typography)(({theme})=> ({
    fontWeight: 500,
    fontSize: '1.2rem'
}))

const FlexBox = styled(Box)(({theme})=> ({
    margin: '12px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& span': {
        display: 'flex',
        gap: '1rem',
    }
}))

const StyledIcon = styled(Circle)(({theme})=> ({
    fontSize: '.4rem'
}))
const StyledListIcon = styled(ListItemIcon)(({theme})=> ({
    minWidth: '20px'
}))
const StyledTableHead = styled(TableHead)(({theme})=> ({
   background: grey[200]
}))

const PropertySlider = styled(OwlCarousel)(({theme})=> ({
    position: 'relative',
    '& .owl-carousel': {
        position: 'relative',
        '&:hover .owl-nav': {
            opacity: 1
        }
    },
    '& .owl-nav': {
        // opacity: 0,
        width: '100%',
        position: 'absolute',
        display: 'flex',
        top: '40%',
        justifyContent: 'space-between',
        transform: 'TranslateY(50%)',
        transition: 'all .2s ease-in'
    },
    '& .owl-prev': {
        padding: '1rem',
        background: alpha('#000', 0.5),
        color: '#fff'

    },
    '& .owl-next': {
        padding: '1rem',
        background: alpha('#000', 0.5),
        color: '#fff'
    }
}))

const InputField = styled(TextField)(({theme}) => ({
    marginBottom: '1.5rem',
}))

const Labels = styled(Typography)(({theme}) => ({
    fontSize: '1rem',
    marginBottom: '10px',
    fontWeight: 500
}))



const PropertyDetails = (props) => {
    const currentUser = useSelector( state => state.currentUser)
    const dispatch = useDispatch()
    const [data, setData] = useState()
    const [similar, setSimilar] = useState()
    const [soldListings, setSoldListings] = useState()
    const [loading, setLoading] = useState(true)
    const [share, setShare] = useState(false)
    const {id, vendor} = useParams()
    const [value, setValue] = useState('1');
    const [open, setOpen] = useState(false)
    const [show, setShow] = useState(false)
    const [emailIndex, setEmailIndex] = useState([0])
    const [currEmail, setCurrEmail] = useState('')
    const [email1, setEmail1] = useState('')
    const [email2, setEmail2] = useState('')
    const [email3, setEmail3] = useState('')
    const [email4, setEmail4] = useState('')
    const [commission, setCommission] = useState(false)
    const [marketDays, setMarketDays] = useState();
    const [load, setLoad] = useState(false)
    const [isFav, setIsFav] = useState(false)
    const pageUrl = window.location.href
    const [notFound, setNotFound] = useState(false)
    const [note, setNote] = useState('')
    const [shareLoad, setShareLoad] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [loadBtn, setLoadBtn] = useState(false)
    const [page, setPage] = useState(null)
    const [disable, setDisable] = useState(false)
    const [response, setResponse] = useState({open: false, status: null})
    const [openDates, setOpenDates] = useState({start: '', end: ''})
    const path = useLocation().pathname
    const [listingID, setListingID] = useState()
    const [homePrice, setHomePrice] = useState()
    const [downPrice, setDownPrice] = useState(0)
    const [downPercent, setDownPercent] = useState(20)
    const [interestRate, setInterestRate] = useState(6.94)
    const [loanDuration, setLoanDuration] = useState('30')
    const [transMode, setTransMode] = useState('DRIVING')
    const [directions, setDirections] = useState()
    const [addCommute, setAddCommute] = useState(false)
    const [commutes, setCommutes] = useState([])
    const [routeIndex, setRouteIndex] = useState()
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [loanAmount, setLoanAmount] = useState(0);
    const [propertyTax, setPropertyTax] = useState(0);
    const [HOA, setHOA] = useState(0);
    const [homeIns, setHomeIns] = useState(0);
    const [startRoute, setStartRoute] = useState(false);
    const [startPosition, setStartPosition] = useState(0)

    const destinationRef = useRef()

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
        libraries: ['places']
    })

    useEffect(()=> {
        setRouteIndex(commutes.length)
	},[commutes])

    useEffect(()=> {
        if(currentUser){
            const res = currentUser.favs.filter(el => {
                return el.property_id === parseInt(id)
            }).length > 0
            setIsFav(res)
        }else{
            setIsFav(false)
        }
    },[currentUser, id])

    useEffect(()=>{
        const fetchData = async () => {
            if(path.startsWith('/listing')){
                try {
                    if(vendor === 'mls'){
                        const { data: res } = await listingURL.get(`mls/${id}/`)
                        const prop = res?.data
                        setData(prop)
                        setHomePrice(prop?.listPrice)
                        getDaysOnMarket(prop?.listDate)
                        setLoading(false)

                        const { data: similar} = await listingURL.get(`residential/mls/?q=${prop?.address?.city?.toLowerCase()}&limit=15`)
                        const { data: soldList} = await listingURL.get(`residential/mls/sold/?q=${prop?.address?.city?.toLowerCase()}&limit=15`)

                        const result = filterListingsWithoutPhotos(similar?.data)
                        const sold = filterListingsWithoutPhotos(soldList?.data)
                        const similarListing = result?.filter(el => el.mlsId !== prop?.mlsId)
                        console.log(sold)
                        setSimilar(similarListing)
                        setSoldListings(sold)
                        return

                    }if(vendor === 'crmls'){
                        const { data: res } = await listingURL.get(`crmls/${id}/`)
                        const prop = res?.data
                        setData(prop)
                        setHomePrice(prop?.listPrice)
                        getDaysOnMarket(prop?.listDate)
                        setLoading(false)

                        const {data: similar} = await listingURL.get(`residential/crmls/?q=${prop?.address?.city?.toLowerCase()}&limit=15`)
                        const { data: soldList} = await listingURL.get(`residential/crmls/sold/?q=${prop?.address?.city?.toLowerCase()}&limit=15`)
                        
                        const result = filterListingsWithoutPhotos(similar?.data)
                        const sold = filterListingsWithoutPhotos(soldList?.data)
                        const similarListing = result?.filter(el => el.mlsId !== prop?.mlsId)

                        setSimilar(similarListing)
                        setSoldListings(sold)
                        return
                    }
    
                } catch (error) {
                    //console.log(error.response.data)
                    setLoading(false)
                    setNotFound(true)
                }

            }if(path.startsWith('/open-houses')){
                try {
                    if(vendor === 'mls'){
                        const { data: res } = await listingURL.get(`openhouse/mls/${id}/`)
                        const prop = res?.data

                        setData(prop)
                        setLoading(false)
                        setHomePrice(prop?.listPrice)
                        setOpenDates({start: prop?.openHouse?.date, end: prop?.openHouse?.date})
                        getDaysOnMarket(prop?.listDate)

                        const {data: similar} = await listingURL.get(`openhouse/merge_listings/?q=${prop?.address?.city?.toLowerCase()}&property_type=${prop?.property?.type}&limit=15`)
                        const result = filterOpenListingsWithoutPhotos(similar?.data)
                        const mapRes = result?.filter(el => el.mlsId !== prop?.mlsId)
                        
                        setSimilar(mapRes)
                        return
    
                    }if(vendor === 'crmls'){
                        const { data: res } = await listingURL.get(`openhouse/crmls/${id}/`)
                        const prop = res?.data

                        setData(prop)
                        setLoading(false)
                        setHomePrice(prop?.listPrice)
                        setOpenDates({start: prop?.openHouse?.startTime?.split('-'), end: prop?.openHouse?.endTime?.split('-')})
                        getDaysOnMarket(prop?.listDate)

                        const {data: similar} = await listingURL.get(`openhouse/crmls/?q=${prop?.address?.city?.toLowerCase()}&property_type=${prop?.property?.type}&limit=15`)
                        const result = filterOpenListingsWithoutPhotos(similar?.data)
                        const mapRes = result?.filter(el => el.mlsId !== prop?.mlsId)
                        
                        setSimilar(mapRes)
                        return
                    }
    
                } catch (error) {
                    //console.log(error.response.data)
                    setLoading(false)
                    setNotFound(true)
                }
            }if(path.startsWith('/sold-homes')){
                try {
                    if(vendor === 'mls'){
                        const { data: res } = await listingURL.get(`sold/mls/${id}/`)
                        const prop = res?.data

                        setData(prop)
                        setHomePrice(prop?.listPrice)
                        getDaysOnMarket(prop?.listDate)
                        setLoading(false)

                        const { data: similar} = await listingURL.get(`residential/mls?q=${prop?.address?.city?.toLowerCase()}&limit=15`)
                        const { data: soldList} = await listingURL.get(`residential/mls/sold/?q=${prop?.address?.city?.toLowerCase()}&limit=15`)

                        const result = filterListingsWithoutPhotos(similar?.data)
                        const sold = filterListingsWithoutPhotos(soldList?.data)
                        const similarListing = result?.filter(el => el.mlsId !== prop?.mlsId)
                        
                        setSimilar(similarListing)
                        setSoldListings(sold)
                        return

                    }if(vendor === 'crmls'){
                        const { data: res } = await listingURL.get(`sold/crmls/${id}/`)
                        const prop = res?.data
                        setData(prop)
                        setHomePrice(prop?.listPrice)
                        getDaysOnMarket(prop?.listDate)
                        setLoading(false)

                        const {data: similar} = await listingURL.get(`residential/crmls/?q=${prop?.address?.city?.toLowerCase()}&limit=15`)
                        const { data: soldList} = await listingURL.get(`residential/crmls/sold/?q=${prop?.address?.city?.toLowerCase()}&limit=15`)
                        
                        const result = filterListingsWithoutPhotos(similar?.data)
                        const sold = filterListingsWithoutPhotos(soldList?.data)
                        const similarListing = result?.filter(el => el.mlsId !== prop?.mlsId)

                        setSimilar(similarListing)
                        setSoldListings(sold)
                        return
                    }
    
                } catch (error) {
                    //console.log(error.response.data)
                    setLoading(false)
                    setNotFound(true)
                }
            }if(path.startsWith('/rental')){
                try {
                    if(vendor === 'mls'){
                        const { data: res } = await listingURL.get(`mls/${id}/`)
                        const prop = res?.data

                        setData(prop)
                        setHomePrice(prop?.listPrice)
                        getDaysOnMarket(prop?.listDate)
                        setLoading(false)

                        const { data: similar} = await listingURL.get(`rental/mls?q=${prop?.address?.city?.toLowerCase()}&limit=15`)
                        const { data: soldList} = await listingURL.get(`rental/mls/sold/?q=${prop?.address?.city?.toLowerCase()}&limit=15`)

                        const result = filterListingsWithoutPhotos(similar?.data)
                        const sold = filterListingsWithoutPhotos(soldList?.data)
                        const similarListing = result?.filter(el => el.mlsId !== prop?.mlsId)
                        
                        setSimilar(similarListing)
                        setSoldListings(sold)
                        return

                    }if(vendor === 'crmls'){
                        const { data: res } = await listingURL.get(`crmls/${id}/`)
                        const prop = res?.data
                        setData(prop)
                        setHomePrice(prop?.listPrice)
                        getDaysOnMarket(prop?.listDate)
                        setLoading(false)

                        const {data: similar} = await listingURL.get(`rental/crmls/?q=${prop?.address?.city?.toLowerCase()}&limit=15`)
                        const { data: soldList} = await listingURL.get(`rental/crmls/sold/?q=${prop?.address?.city?.toLowerCase()}&limit=15`)
                        
                        const result = filterListingsWithoutPhotos(similar?.data)
                        const sold = filterListingsWithoutPhotos(soldList?.data)
                        const similarListing = result?.filter(el => el.mlsId !== prop?.mlsId)

                        setSimilar(similarListing)
                        setSoldListings(sold)
                        return
                    }
    
                } catch (error) {
                    //console.log(error.response.data)
                    setLoading(false)
                    setNotFound(true)
                }
            }
        } 
        fetchData()
    },[id, vendor, path])

    useEffect(() => {
        if(homePrice){
            const downPay = Math.ceil((downPercent / 100) * homePrice)
            setDownPrice(downPay)
            setLoanAmount(homePrice - downPay);
            setPropertyTax(Math.ceil((0.012 * homePrice) / 12))
            setHomeIns(Math.ceil(0.0023 * downPay))
        }
        
    // eslint-disable-next-line
    },[homePrice])

    useEffect(() => {
        calMortgage()
        // eslint-disable-next-line
    },[loanAmount, loanDuration, interestRate])

    const calculateLoanAmount = () => {
        setLoanAmount(homePrice - downPrice);
        return loanAmount;
    }

    const calMortgage = () => {
        const principal = calculateLoanAmount()
        const interestPerMonth = (interestRate / 12) / 100;
        const periodInMonths = loanDuration * 12;

        const num = interestPerMonth * ( Math.pow((1 + interestPerMonth), periodInMonths) )
        const denom = Math.pow((1 + interestPerMonth), periodInMonths) - 1
        const monthly_payment = principal * (num / denom)

        setMonthlyPayment(Math.round(monthly_payment))   
    }

    const chartData = {
        labels: [],
        // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
        datasets: [
            {
              label: 'Mortgage Payment',
              data: [
                `${monthlyPayment + parseInt(propertyTax) + parseInt(HOA) + parseInt(homeIns)}`,  
                propertyTax === '' ? 0 : propertyTax, 
                HOA === '' ? 0 : HOA, 
                homeIns === '' ? 0 : homeIns
            ],
              // you can set indiviual colors for each bar
              backgroundColor: [
                '#1378A5',
                '#FFAA01',
                '#39aaa9',
                '#24c8c7',
              ],
              borderWidth: 1,
            }
        ]
    }

    const centerData = {
        id: 'principal_monthly_payment',
        beforeDatasetsDraw(chart, args, pluginOptions) {
            const {ctx, data, chartArea: {width, height}} = chart;

            ctx.save();
            ctx.font = '14px Roboto';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.fillText( `Est. Payment`, width / 2, height / 2 + -20)

            ctx.fontHeight = 80;
            ctx.font = 'bolder 28px Roboto';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.fillText( `$${+data.datasets[0].data[0]}`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
            ctx.restore()

            ctx.font = '14px Roboto';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.fillText( `/month`, width / 2, height / 2 + 35)
        },
    }

    const getDownPayment = (percent) => {
        setDownPercent(percent)
        const pay = (percent / 100) * homePrice
        setDownPrice(Math.ceil(pay))
        setLoanAmount(homePrice - pay);
    }

    const getDownPercent = (amt) => {
        setDownPrice(amt)
        const per = (amt / homePrice) * 100
        setDownPercent(Math.ceil(per))
        setLoanAmount(homePrice - amt);
    }

    const calcHomePrice = (amt) => {
        console.log(amt)
        setHomePrice(amt)
        const pay = (downPercent / 100) * amt
        setDownPrice(Math.ceil(pay))
        setLoanAmount(amt - downPrice);
    }

    const calRoute = async(val, mode) => {
        if(val === '') return
        // console.log(`${data?.address?.full},${data?.address?.city}`)
        //eslint-disable-next-line no-undef
        const directionalService = new google.maps.DirectionsService()
        const result = await directionalService.route({
            origin: `${data?.address?.full},${data?.address?.city}`,
            destination: val,
            //eslint-disable-next-line no-undef
            travelMode: mode === 'DRIVING' ? google.maps.TravelMode.DRIVING : mode === 'BICYCLING' ? google.maps.TravelMode.BICYCLING : mode === 'TRAIN' ? google.maps.TravelMode.TRANSIT : google.maps.TravelMode.WALKING,
        })

        setDirections(result)
        setStartRoute(true)
        const mapRoute = {
            mode,
            duration: result?.routes[0]?.legs[0]?.duration?.text,
            distance: val,
        }
        setCommutes(prev => {
            const res = prev.filter(obj => obj.distance !== val)
            return [mapRoute, ...res]
        })

        // RESET//
        // destinationRef.current.value = '';
        setTransMode('DRIVING');
        setAddCommute(false)
    }

    const deleteRoute = (val) => {
        const routes = commutes.filter(obj => obj.distance !== val )
        const firstRoute = routes[0]
        
        if(routes.length === 0){
            setDirections()
            setStartRoute(false)
            setCommutes([])
        }else{
            // set last route//
            setCommutes(routes) 
            calRoute(firstRoute?.distance, firstRoute?.mode) 
        }
    }

    const getDaysOnMarket = (listDate) => {
        if(listDate){
            const diff = new Date(Date.now()).getTime() - new Date(listDate).getTime()
            return setMarketDays(`${Math.round(diff / (1000 * 3600 * 24))} days on BPOHomes` )
        }else{
            return '0 days'
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const addEmails = () => {
        setEmailIndex(prev => {
            if(prev.length > 4){
                return prev
            }
            const newVal = prev + 1
            return [...prev, newVal]
        })
    }

    const removeEmail = (index) => {
        const newIndex = emailIndex.filter(el => emailIndex[index] !== el)
        if(index === 1){
            setEmail1('')
        }if(index === 2){
            setEmail2('')
        }if(index === 3){
            setEmail3('')
        }if(index === 4){
            setEmail4('')
        }
        setEmailIndex(newIndex)

    }

    const closeShare = () => {
        setShare(false)
        setNote('')
        setCurrEmail('')
        setEmail1('')
        setEmail2('')
        setEmail3('')
        setEmail4('')
        setEmailIndex([1])
    }

    const updateEmails = (e, index) => {
        if(index === 0){
            setCurrEmail(e.target.value)
        }if(index === 1){
            setEmail1(e.target.value)
        }if(index === 2){
            setEmail2(e.target.value)
        }if(index === 3){
            setEmail3(e.target.value)
        }if(index === 4){
            setEmail4(e.target.value)
        }
    }

    const shareListing = async() => {
        const data = {
            emails: [currEmail, email1, email2, email3, email4],
            property_url : window.location.href,
            note
        }
        if(note === '' || currEmail === ''){
            dispatch({type:'ERROR', payload: 'Invalid, provide an email and notes'})
            return
        }
        setShareLoad(true)
        try {
            await base.post(`/leads/share-property/`, data )
            setShareLoad(false)
            setNote('')
            setCurrEmail('')
            setEmail1('')
            setEmail2('')
            setEmail3('')
            setEmail4('')
            setShare(false)
            dispatch({type:'SUCCESS', payload: 'Email sent successfully'})
        } catch (error) {
            setShareLoad(false)
            dispatch({type:'ERROR', payload: 'Sorry, could not send email'})
        }
        
    }

    const addToFav = async() => {
        if(currentUser){
            if(currentUser.favs.filter(el => el.property_id === parseInt(id)).length > 0){
                setLoad(true)
                const status = await props.removeFavorite(id)
                if(status === 204){
                    setLoad(false)
                    dispatch({type: 'SUCCESS', payload: 'Property removed from favorites'})
                }else{
                    setLoad(false)
                    dispatch({type: 'SUCCESS', payload: 'Sorry, could not add to favorite'})
                }
            }else{
                // call endpoint
                setLoad(true)
                const body = {
                    user_id: currentUser.id,
                    property_id: data?.mlsId,
                    address: data?.address.full,
                    price: Number(data?.listPrice),
                    image: data?.photos[0],
                    bedrooms: data?.property?.bedrooms,
                    baths: data?.property?.bathsFull,
                    area: data?.property?.area,
                    list_date: new Date(data?.listDate)
                }
                const status = await props.addToFavorite(body)
                if(status === 200){
                    setLoad(false)
                    dispatch({type: 'SUCCESS', payload: 'Property added to favorites'})
                }else{
                    setLoad(false)
                    dispatch({type: 'SUCCESS', payload: 'Sorry, could not add to favorite'})
                }
            }
        }else{
            dispatch({type: 'AUTH_LOGIN'})
        }
    }

    const dates = getAllDaysInMonth(new Date().getFullYear(), new Date().getMonth())

	const formState = { 
		meetingAddress: '',
		meetingType: '',
		meetingDate: '',
		meetingTime: '',
		firstname: '',
		lastname: '',
		phone: '',
        phoneExt: '',
		email: '',
		otherInfo: ''
	}

	const formReducer = (state, action) => {
		switch (action.type) {
			case 'TYPE': 
				return {...state, meetingType: action.payload}
			case 'DATE':
				return {...state, meetingDate: action.payload}
			case 'TIME':
				return {...state, meetingTime: action.payload}
			case 'FIRSTNAME':
				return {...state, firstname: action.payload}
			case 'LASTNAME':
				return {...state, lastname: action.payload}
			case 'PHONE':
				return {...state, phone: formatPhoneNumber(action.payload)}
            case 'EXT':
                return {...state, phoneExt: action.payload}
			case 'EMAIL':
				return {...state, email: action.payload}
			case 'OTHER_INFO':
				return {...state, otherInfo: action.payload}
			case 'RESET':
				return formState
			default:
				return state;
		}
	}

	const [formInput, dispatchForm] = useReducer(formReducer, formState)

    const startRequest = () => {
        setOpen2(true)
        setPage(0)
    }

    const stepBack = () => {
        if(page === 1){
            setPage(0)
        }
    }
    const onFormSubmit = async() => {
        if(page === 0){
            if(formInput.meetingDate === '' || formInput.meetingTime === '' || formInput.meetingType === ''){
                dispatch({type:'ERROR', payload: 'All fields are required'})
                return 
            }
            setPage(1)
        }
        if(page === 1){
            // do checks & submit
            if(formInput.firstname === '' || formInput.lastname === '' || formInput.email === '' || formInput.phone === '' ){
                dispatch({type:'ERROR', payload: 'Provide all required fields'})
                return
            }
            
            setDisable(true)
            setLoadBtn(true)
            const body = {
				property_address : data?.address.full + ' ' + data?.address.city + ', ' + data?.address.state,
				schedule_type : formInput.meetingType,
				meeting_date : new Date(formInput.meetingDate).toDateString(),
				meeting_time : formInput.meetingTime,
				first_name : formInput.firstname,
				last_name : formInput.lastname,
				email : formInput.email,
				phone : formInput.phoneExt + formInput.phone,
				other_information : formInput.otherInfo,
				lead_type: 'buy_tour',
                address_link: path.startsWith('/listing') ? `/listing/${id}/details/${vendor}` : `/open-houses/${id}/details/${vendor}`
			}
            //console.log(data)
            //make api request
            try {
                const res = await base.post('leads/property/request/', body)
                if(res.data){
                    //console.log(res.data)
                    setResponse({open: true, status: 'success'})
                    setOpen2(false)
                    setLoadBtn(false)
                    setDisable(false)
                    setTimeout(() => {
                        setResponse({open: false, status: null})
                    }, 1000);
                }
            } catch (error) {
                //console.log(error.response)
                setDisable(false)
                setLoadBtn(false)
                setResponse({open: true, status: 'error'})
            }

        }
    }

    const onHandleClose = () => {
        dispatchForm({type: 'RESET'})
        setResponse({open: false, status: null})
        setOpen2(false)
    }

    const accessDashboard = (link) => {
        const accessToken = localStorage.getItem('uta')
        const refreshToken = localStorage.getItem('utr')
        window.location.assign(`${process.env.REACT_APP_BPOHOMESTECH_REDIRECT}?uta=${accessToken}&utr=${refreshToken}&link=${link}`)
    }


  return (
    <div>
        { !loading &&
            <SEO>
                <title>{`${data?.property?.subType} ${data?.property?.bedrooms}BR/${data?.property?.bathsFull}BA ${data?.property?.style } Home for Sale in ${data?.address?.city}, ${data?.address?.state}`}</title>
                <meta name='og:title' content={`${data?.property?.subType} ${data?.property?.bedrooms}BR/${data?.property?.bathsFull}BA ${data?.property?.style} Home for Sale in ${data?.address?.city}, ${data?.address?.state}`} />
                <meta
                    name='description'
                    content={`Discover this stunning ${data?.property?.bedrooms} bedroom, ${data?.property?.bathsFull} bathroom ${data?.property?.style} ${data?.property?.subType} in ${data?.address?.city}, ${data?.address?.state}, featuring ${data?.property?.area} sq ft of living space, a ${data?.property?.lotSizeArea} sq ft lot, and built in ${data?.property?.yearBuilt}. View the listing now on BPOHomes.`}
                />
                <meta
                    name='og:description'
                    content={`Discover this stunning ${data?.property?.bedrooms} bedroom, ${data?.property?.bathsFull} bathroom ${data?.property?.style} ${data?.property?.subType} in ${data?.address?.city}, ${data?.address?.state}, featuring ${data?.property?.area} sq ft of living space, a ${data?.property?.lotSizeArea} sq ft lot, and built in ${data?.property?.yearBuilt}. View the listing now on BPOHomes.`}
                />
            </SEO>
        }
        {
            loading ? <Loader /> : notFound ? 
            <Container sx={{height: '60vh'}}>
                <Box mt={3} sx={{ width: '100%', padding: '3rem', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                    <img src={HomeIMG} width='30%' style={{marginBottom:'1rem'}} alt='home' />
                    <Typography textAlign={'center'} variant='h6'>Not Available</Typography>
                    <Typography color='GrayText' mb='2rem' textAlign={'center'}>Sorry, this property is not available</Typography>
                    <Button href='/homes-for-sale' variant='contained' color='secondary' disableElevation>Back to Search</Button>
                </Box>
            </Container> 
            :
            <Container maxWidth='xl'>
                <Hidden mdUp>
                    <Box onClick={startRequest} sx={{
                        padding: '1rem', zIndex: 30,
                        position: 'fixed', bottom: 0, 
                        color: '#fff',left: 0, right: 0,
                        bgcolor: 'secondary.main'}}>
                        <Typography textAlign={'center'}>Schedule a Tour</Typography>
                    </Box>
                </Hidden>
                <Box mt={'2rem'}>
                    {/* Image gallery */}
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} md={12} lg={ data?.photos?.length === 1 ? 12 : 9} >
                        <Box sx={{position: 'relative'}}>
                            { (path.startsWith('/open-houses') && vendor === 'crmls') &&
                                <Box sx={{position: 'absolute', top: '3%', left: '3%', zIndex: 99, display: 'flex', gap: '1rem' }} >
                                    <Chip size='small' color='secondary' label={<Typography variant='body2' sx={{fontSize: '.7rem', fontWeight: 400}}>Open House</Typography>} /> 
                                    <Chip size='small' color='secondary' 
                                        label={<Typography variant='body2' sx={{fontSize: '.7rem', fontWeight: 400}}>{crmlsOpenHouseTime(openDates.start, openDates.end)}</Typography>} /> 
                                </Box>
                            }
                            { (path.startsWith('/open-houses') && vendor === 'mls') &&
                                <Box sx={{position: 'absolute', top: '3%', left: '3%', zIndex: 99, display: 'flex', gap: '1rem' }} >
                                    <Chip size='small' color='secondary' label={<Typography variant='body2' sx={{fontSize: '.7rem', fontWeight: 400}}>Open House</Typography>} /> 
                                    <Chip size='small'
                                        color='secondary' 
                                        label={ <Typography variant='body2' sx={{fontSize: '.7rem', fontWeight: 400}}>{ getOpenHouseTime(openDates.start, openDates.end) } </Typography> } 
                                    />
                                </Box>
                            }
                            { path.startsWith('/sold-homes') &&
                                <Box sx={{position: 'absolute', top: '3%', left: '3%', zIndex: 99, display: 'flex', gap: '1rem' }} >
                                    <Chip size='medium' color='secondary' label={<Typography variant='body1' sx={{fontSize: '.8rem', fontWeight: 400}}>Sold Homes</Typography>} /> 
                                </Box>
                            }
                            { ( path.startsWith('/listing') && data?.openHouseSchedule && vendor === 'crmls') &&
                                <Box sx={{position: 'absolute', top: '3%', left: '3%', zIndex: 99, display: 'flex', gap: '1rem' }} >
                                    <Chip size='small' color='secondary' label={<Typography variant='body2' sx={{fontSize: '.7rem', fontWeight: 400}}>Open House</Typography>} /> 
                                
                                </Box>
                            }
                            { ( path.startsWith('/listing') && data?.openHouseSchedule && vendor === 'mls') &&
                            <Box sx={{position: 'absolute', top: '3%', left: '3%', zIndex: 99, display: 'flex', gap: '1rem' }} >
                                    <Chip size='small' color='secondary' label={<Typography variant='body2' sx={{fontSize: '.7rem', fontWeight: 400}}>Open House</Typography>} /> 
                                    
                                </Box>
                            }
                            <PropertySlider 
                                    items={1} autoplay={true} autoplaySpeed={500} margin={20} loop={true} lazyLoad={true} startPosition={1} height={'100%'}
                                        nav={true} navElement="div" navText={[`<i class='fas fa-arrow-left'></i>`, `<i class='fas fa-arrow-right'></i>`]}
                                        responsive={ { 1400 : {items : '1'}, 1200 : {items : '1'}, 760 : {items : '1'}, 340 : {items : '1'} } }
                                    > 
                                    {
                                        data?.photos?.map((item, index) => {
                                            return (
                                                <Box key={index} sx={{ 
                                                    backgroundImage: `url(${item})`, 
                                                    backgroundSize: 'cover', 
                                                    backgroundPosition: 'center', 
                                                    width: '100%', 
                                                    height: {xs: '20rem',lg:'36rem'},
                                                    borderRadius: {xs: '10px', sm: '10px',md: '10px', lg: data?.photos?.length === 1 ? '10px' : '10px 0 0 10px'},
                                                    overflow: 'hidden'
                                                    }} 
                                                />
                                            )

                                        })
                                    }
                            </PropertySlider>
                            
                            </Box>
                        </Grid>
                        <Hidden lgDown>
                            <Grid item xs={12} sm={12} md={12} lg={3} sx={{ margin: 0, '& #imgList': { height: '100%'}}} >
                                <ImageList variant='quilted' id='imgList' cols={1} gap={1} rowHeight={555 / 2} sx={{margin: 0}}>
                                    {
                                       data?.photos?.slice(1, 3).map((img, index) => {
                                            return (
                                                <ImageListItem sx={{cursor: 'pointer', position: 'relative'}} key={index} onClick={()=>setOpen(true)}>
                                                    <img src={`${img}`} style={{objectFit: 'cover'}} alt={`property_img_${index}`} loading='lazy'/>
                                                    { index > 0 && <Button variant='contained' color='paper' sx={{position: 'absolute', bottom: '10%', right: '10%', textTransform: 'none'}}>View {data?.photos?.length} photos</Button> }
                                                </ImageListItem>
                                            )
                                        })
                                    }  
                                </ImageList>
                            </Grid>

                        </Hidden>
                        <Grid item xs={12} sm={12} md={12} lg={data?.photos?.length === 1 ? 12 : 9} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '2rem' }}>
                            <Typography noWrap sx={{zIndex: 5}}>{ data?.address.full === null ? '' : _.startCase(data?.address.full + data?.address.city + data?.address.state + data?.address.postalCode) } </Typography>
                            <Box display={'flex'} gap={'1rem'} alignItems='center' sx={{ margin: '-6rem 2rem 0 0', zIndex: 10 }}>
                                <IconBtn sx={{bgcolor: isFav ? 'secondary.main' : null}} onClick={addToFav}>
                                    {
                                        load ? <CircularProgress size='1.6rem' color='secondary' />  :
                                        isFav ? <Favorite fontSize='medium' sx={{color: '#fff'}} /> : <FavoriteBorder fontSize='medium' />
                                    }
                                </IconBtn>
                                <IconBtn onClick={()=> {
                                     if(!currentUser){
                                        dispatch({type: 'AUTH_LOGIN'})
                                     }else{
                                        setShare(true)
                                     }
                                }}><Share fontSize='medium' /></IconBtn>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={8} lg={9} sx={{paddingRight: {xs: 0, sm: 0, md: '3rem', lg: '4rem'}}}>
                            <Box mt={'2rem'} sx={{
                                display: 'flex', 
                                flexDirection: { xs: 'column', sm: 'column', md:'row', lg: 'row' }, 
                                justifyContent: {xs:'flex-start', sm: 'flex-start', md:'flex-start', lg: 'space-between'}, 
                                alignItems: 'center', width: '100%'}}
                            >
                                
                                <Box width='100%'>
                                    {
                                        data?.property?.type === 'Residential' && path.startsWith('/listing') ?
                                        <Typography sx={{fontSize: '.9rem'}}><Circle sx={{fontSize: '.6rem', color: 'red'}} /> For Sale</Typography>
                                        : 
                                        data?.property?.type === 'Residential' && path.startsWith('/open-houses') ? 
                                        <Typography sx={{fontSize: '.9rem'}}><Circle sx={{fontSize: '.6rem', color: 'red'}} /> Open Houses</Typography>
                                        :
                                        data?.property?.type === 'Residential' && path.startsWith('/sold-homes') ? 
                                        <Typography sx={{fontSize: '.9rem'}}><Circle sx={{fontSize: '.6rem', color: 'red'}} /> SOLD ON {new Date(data?.closeDate)?.toLocaleDateString('en-US', {month:'short', day: 'numeric', year:'numeric'}).toUpperCase()}</Typography>
                                        :
                                        <Typography sx={{fontSize: '.9rem'}}><Circle sx={{fontSize: '.6rem', color: 'red'}} /> For Rent</Typography>
                                    }

                                    {
                                        data?.property?.type === 'Residential' && path.startsWith('/sold-homes') ? 
                                        <Typography my={1} sx={{fontWeight: 600}} variant='h4'>$ {data?.closePrice?.toLocaleString()}</Typography>
                                        :
                                        <Typography my={1} sx={{fontWeight: 600}} variant='h4'>$ {data?.listPrice?.toLocaleString()}</Typography>
                                    }
                                    {  (data?.property?.type === 'Residential' && path.startsWith('/listing')) && 
                                        <Box display={'flex'} gap='1rem' alignItems={'center'}>
                                            <Typography variant='body1' sx={{width: {xs: 'auto', sm: 'auto', md:'50%', lg: 'auto'}}} noWrap>Buy with BPO Homes Partner Agents:</Typography>
                                            <Button disableRipple onClick={()=> setShow(true)} 
                                                sx={{fontWeight: 600, color: '#1378A5', fontSize: '1.1rem'}}>
                                                ${(data?.listPrice - (data?.listPrice * 0.01))?.toLocaleString()} {<KeyboardArrowRight fontSize='small' />} 
                                            </Button>
                                        </Box>
                                    }
                                    {  (data?.property?.type === 'Residential' && path.startsWith('/rental')) && 
                                        <Box display={'flex'} gap='1rem' alignItems={'center'}>
                                            <Typography variant='body1' sx={{width: {xs: 'auto', sm: 'auto', md:'50%', lg: 'auto'}}} noWrap>Rent with BPO Homes Partner Agents:</Typography>
                                            <Button disableRipple onClick={()=> setShow(true)} 
                                                sx={{fontWeight: 600, color: '#1378A5', fontSize: '1.1rem'}}>
                                                ${(data?.listPrice - (data?.listPrice * 0.01))?.toLocaleString()} {<KeyboardArrowRight fontSize='small' />} 
                                            </Button>
                                        </Box>
                                    }
                                    {  (data?.property?.type === 'Residential' && path.startsWith('/sold-homes')) && 
                                            <Typography variant='body1' sx={{width: {xs: 'auto', sm: 'auto', md:'50%', lg: 'auto'}}} noWrap>Sold Price</Typography>
                                    }
                                </Box>

                                <Box width='100%' sx={{ 
                                    display: 'flex', 
                                    justifyContent: {xs:'flex-start', sm:'flex-start', md:'flex-end', lg:'flex-end'},
                                    gap: {xs: '2rem', sm: '2rem', md: '1rem', lg: '3rem'},
                                    
                                    }}  textAlign={'center'}>
                                    <Stack >
                                        <Typography variant='h6' sx={{fontWeight: 600}}>{data?.property.bedrooms}</Typography>
                                        <Typography color={'textSecondary'}>Bed</Typography>
                                    </Stack>
                                    <Divider orientation='vertical' flexItem sx={{background: grey[200], border: 'none', width: '1px'}} />
                                    <Stack>
                                        <Typography variant='h6' sx={{fontWeight: 600}}>{data?.property.bathrooms ? data?.property.bathrooms : data?.property.bathsFull}</Typography>
                                        <Typography color='textSecondary'>Bath</Typography>
                                    </Stack>
                                    <Divider orientation='vertical' flexItem sx={{background: grey[200], border: 'none', width: '1px'}} />
                                    <Stack>
                                        <Typography variant='h6' sx={{fontWeight: 600}}>{data?.property.area ? data.property.area : 0}</Typography>
                                        <Typography color={'textSecondary'}>Sqft Ft.</Typography>
                                    </Stack>
                                    
                                </Box>

                            </Box>
                            <Divider sx={{margin: '2rem 0', background: grey[100]}}/>
                            
                            <Box mt={'2rem'}>
                                <Typography variant='h5' mb={1} sx={{fontWeight: 600}}>About This Home</Typography>
                                <Typography paragraph color='GrayText'>Days on the market: <Chip size='small' color='secondary' label={marketDays} /></Typography>
                                <Typography paragraph sx={{
                                    textAlign: 'justify',
                                    overflow: 'hidden',
                                    'display': '-webkit-box',
                                    '-webkit-line-clamp': 2,
                                    '-webkit-box-orient': 'vertical',
                                    }}>{data?.remarks}</Typography>
                                
                                    {/* Listing Agent */}
                                    <Box mt={3} sx={{display: 'flex', gap: '1rem', justifyContent:'flex-start', alignItems: 'flex-start' }}>
                                        <Box>
                                            <Avatar sx={{width: '5rem', height: '5rem', zIndex: 2}} />
                                            <Chip size='small' 
                                            label={'Add Photo'} onClick={()=> {
                                                if(!currentUser){
                                                    dispatch({type: 'AUTH_LOGIN'})
                                                }else{
                                                    accessDashboard('/profile')
                                                }
                                            }}
                                            sx={{ 
                                                mt: -2, zIndex: 10, position: 'relative', 
                                                bgcolor: 'primary.main', cursor: 'pointer', '&:hover': {
                                                    bgcolor: 'primary.main', color: '#fff'
                                                }
                                            }} />
                                        </Box>
                                        <Stack>
                                            <Typography variant='body1'  color='GrayText'><span style={{color: '#000', marginRight: '.6rem'}}>Listed by {data?.agent.firstName + ' ' + data?.agent.lastName}:</span>{data?.office.name}</Typography>
                                            <Typography variant='body1' color='GrayText'><span style={{color: '#000', marginRight: '.6rem'}}>Last Updated:</span> 
                                            {new Date(data?.listDate).toDateString()}
                                            </Typography>
                                            <Box sx={{display: 'block', gap: '1rem', justifyContent:'flex-start', alignItems: 'flex-start' }}>
                                                <Typography variant='body1' color='GrayText'><span style={{color: '#000', marginRight: '.6rem'}}>Contact:</span> {data?.agent?.contact?.office}</Typography>
                                                <Typography variant='body1' color='GrayText'><span style={{color: '#000', marginRight: '.6rem'}}>Source:</span>MLSListings #{data?.listingId}</Typography>
                                            </Box>
                                        </Stack>
                                    {/* <Button variant='contained' sx={{textTransform: 'none', marginLeft: '5rem'}} disableElevation color='secondary' size='large'>Contact Agent</Button> */}
                                    </Box>
                                

                            </Box>
                            <Divider sx={{margin: '2rem 0', background: grey[100]}}/>

                            {/* Payment Calculator */}
                            <Box>
                                <Typography variant='h5' mb={3} sx={{fontWeight: 500}}>Affordability Calculator</Typography>
                                <Box sx={{padding: '1.5rem', border: '1px solid lightgrey', borderRadius: '10px'}}>
                                    <Grid container columnSpacing={3} >
                                        <Grid item xs={12} sm={3}>
                                            <Labels>Home Price</Labels>
                                            <TextField inputProps={{min: 0, step: 1000}} fullWidth variant='outlined' 
                                            onChange={(e)=> {
                                                calcHomePrice(e.target.value);
                                            }}
                                            value={ homePrice } InputProps={{
                                                endAdornment: <InputAdornment position='start'>$</InputAdornment>
                                            }} />
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Labels>Down Payment</Labels>
                                            <Grid container columnSpacing={1}>
                                                <Grid item xs={8} sm={8}>
                                                    <TextField fullWidth type={'number'} inputProps={{min: 0}} variant='outlined' 
                                                    onChange={(e)=> {
                                                        getDownPercent(e.target.value);
                                                    }}
                                                    value={ downPrice } InputProps={{
                                                        endAdornment: <InputAdornment position='start'>$</InputAdornment>
                                                    }} />
                                                </Grid>
                                                <Grid item xs={4} sm={4}>
                                                    <TextField type={'number'} inputProps={{min: 0}} fullWidth variant='outlined' 
                                                    onChange={(e)=> {
                                                        getDownPayment(e.target.value);
                                                    }}
                                                    
                                                    value={downPercent} InputProps={{
                                                        endAdornment: <InputAdornment position='start'>%</InputAdornment>
                                                    }} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Labels>Interest Rate</Labels>
                                            <TextField fullWidth variant='outlined' type={'number'} inputProps={{min: 0}} 
                                                onChange={(e)=> {
                                                    setInterestRate(e.target.value);
                                                }} 
                                                
                                                value={interestRate} InputProps={{
                                                endAdornment: <InputAdornment position='start'>%</InputAdornment>
                                            }} />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <Labels>Loan Type</Labels>
                                            <TextField select fullWidth value={loanDuration} onChange={(e)=> {
                                                if(e.target.value === '15'){
                                                    setInterestRate(6.23)
                                                }if(e.target.value === '30'){
                                                    setInterestRate(6.94)
                                                }
                                                setLoanDuration(e.target.value);
                                            }} variant='outlined'>
                                                <MenuItem value='30'>30-year fixed</MenuItem>
                                                <MenuItem value='15'>15-year fixed</MenuItem>
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box mt={4}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={5} sx={{ alignItems: 'center' }}>
                                            <Box sx={{width: {xs: '80%', lg:'80%'}}}>
                                                <DoughnutChart chartData={chartData} centerData={centerData} />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={7}>
                                            <FlexBox>
                                                <span>
                                                    <Circle sx={{ color:'#1378A5' }} />
                                                    <Typography sx={{fontWeight: 400}}>Principal and Interest</Typography>
                                                </span>
                                                <TextField disabled sx={{width: '10rem'}} value={monthlyPayment} size='small' variant='outlined' InputProps={{
                                                    endAdornment: <InputAdornment position='start'>$</InputAdornment>
                                                }} />
                                            </FlexBox>
                                            <FlexBox>
                                                <span>
                                                    <Circle sx={{ color:'#FFAA01'}} />
                                                    <Typography sx={{fontWeight: 400}}>Property Tax</Typography>
                                                </span>
                                                <TextField sx={{width: '10rem'}} value={ propertyTax }
                                                type={'number'} inputProps={{min: 0}} defaultValue={0}
                                                onChange={(e) => {
                                                    if(e.target.value === ''){
                                                        setPropertyTax(0)
                                                    }else{
                                                        setPropertyTax(e.target.value);
                                                    }
                                                }} 
                                                size='small' variant='outlined' InputProps={{
                                                    endAdornment: <InputAdornment position='start'>$</InputAdornment>
                                                }} />
                                            </FlexBox>
                                            <FlexBox>
                                                <span>
                                                    <Circle sx={{ color: '#39aaa9'}} />
                                                    <Typography sx={{fontWeight: 400}}>HOA Fees</Typography>
                                                </span>
                                                <TextField sx={{width: '10rem'}} size='small'
                                                    value={HOA} type={'number'} inputProps={{min: 0}}
                                                    onChange={(e) => {
                                                        if(e.target.value === ''){
                                                            setHOA(0)
                                                        }else{
                                                            setHOA(e.target.value);
                                                        }
                                                    }} 
                                                    variant='outlined' InputProps={{
                                                    endAdornment: <InputAdornment position='start'>$</InputAdornment>
                                                }} />
                                            </FlexBox>
                                            <FlexBox>
                                                <span>
                                                    <Circle sx={{ color: '#24c8c7'}} />
                                                    <Typography sx={{fontWeight: 400}}>Home Insurance</Typography>
                                                </span>
                                                <TextField sx={{width: '10rem'}} type={'number'} inputProps={{min: 0}}
                                                value={homeIns} onChange={(e) => {
                                                    if(e.target.value === ''){
                                                        setHomeIns(0)
                                                    }else{
                                                        setHomeIns(e.target.value);
                                                    }
                                                }} size='small' variant='outlined' InputProps={{
                                                    endAdornment: <InputAdornment position='start'>$</InputAdornment>
                                                }} />
                                            </FlexBox>
                                            {/* <FlexBox>
                                                <span>
                                                    <Circle sx={{ color: '#5986f0'}} />
                                                    <Typography sx={{fontWeight: 400}}>Private Mortgage Insurance</Typography>
                                                </span>
                                                <TextField sx={{width: '10rem'}} size='small' variant='outlined' InputProps={{
                                                    endAdornment: <InputAdornment position='start'>$</InputAdornment>
                                                }} />
                                            </FlexBox>
                                            <FlexBox>
                                                <span>
                                                    <Circle sx={{ color: '#a2b2f8'}} />
                                                    <Typography sx={{fontWeight: 400}}>Utilities</Typography>
                                                </span>
                                                <TextField sx={{width: '10rem'}} size='small' variant='outlined' InputProps={{
                                                    endAdornment: <InputAdornment position='start'>$</InputAdornment>
                                                }} />
                                            </FlexBox> */}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>

                            <Divider sx={{margin: '2rem 0', background: grey[100]}}/>

                            {/* Map Commute */}
                            <Box>
                                <Typography variant='h5' sx={{fontWeight: 500, mt: '2.5rem', mb: '1rem'}}>Map Commute</Typography>
                                    <Box sx={{height: '20rem', borderRadius: '15px', overflow: 'hidden', bgcolor: grey[200]}}>
                                        {
                                            isLoaded && 
                                                <GoogleMap 
                                                    mapTypeId='roadmap'
                                                    mapContainerStyle={{
                                                        height: "100%",
                                                        width: "100%"
                                                    }}
                                                    center={{ lat: data?.geo?.lat, lng: data?.geo?.lng }}
                                                    zoom={15}
                                                    clickableIcons={false}
                                                >
                                               {
                                                startRoute ? null : 
                                                <Marker
                                                    position={{ lat: data?.geo?.lat, lng: data?.geo?.lng }}
                                                    // icon={ HouseMarker}
                                                    visible={true}
                                                    
                                                />
                                               }
                                                { directions && <DirectionsRenderer directions={directions} />}
                                                </GoogleMap>

                                        }
                                    </Box>
                                    {
                                        commutes.length > 0 && commutes?.map((com, index) => {
                                            return (
                                                <Box key={index} sx={{mt: '1rem', bgcolor: grey[200], display: 'flex', justifyContent: 'space-between', padding: '1rem', alignItems: 'center', borderRadius: '10px'}}>
                                                    <Box sx={{display: 'flex', gap: '1rem' }}>
                                                        { com?.mode === 'DRIVING' ? <DirectionsCar sx={{color: (routeIndex - routeIndex) === index ? 'primary.main' : null}} /> 
                                                            : com?.mode === 'BICYCLING' ? <DirectionsBikeOutlined sx={{color: (routeIndex - routeIndex) === index ? 'primary.main' : null}} /> 
                                                            : com?.mode === 'TRAIN' ? <DirectionsRailwayFilledOutlined sx={{color: (routeIndex - routeIndex) === index ? 'primary.main' : null}} /> : <DirectionsRun sx={{color: (routeIndex - routeIndex) === index ? 'primary.main' : null}} />}
                                                        <Divider flexItem orientation='vertical' />
                                                        <Typography><span style={{fontWeight: 600}}>{com?.duration}</span> to {com?.distance}</Typography>
                                                    </Box>
                                                    <Box sx={{display: 'flex', gap: '.5rem'}}>
                                                        <IconButton onClick={()=> calRoute(com?.distance, com?.mode)}>
                                                            <NearMe fontSize='small' />
                                                        </IconButton>
                                                        <IconButton onClick={() =>deleteRoute(com?.distance)} sx={{bgcolor: grey[300]}}><Delete fontSize='small' /></IconButton>
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                    }

                                    {
                                        addCommute ? 
                                        <Box mt={3} sx={{padding: '2rem', border: '1px solid lightgrey', position: 'relative'}}>
                                            <Typography gutterBottom variant='h6'>Add a Destination</Typography>
                                            <IconButton onClick={() => {
                                                destinationRef.current.value = '';
                                                setTransMode('DRIVING');
                                                setAddCommute(false)
                                            }} sx={{position: 'absolute', right: '3%', top: '6%'}}><Close fontSize='small' /></IconButton>
                                            <Autocomplete>
                                                <TextField type={'search'} inputRef={destinationRef} size='small' variant='outlined' fullWidth />
                                            </Autocomplete>
                                            <ButtonGroup variant='outlined' color='secondary' sx={{mt: '1rem'}}>
                                                <Button disableRipple disableElevation variant={ transMode === 'DRIVING' ? 'contained' : 'outlined'} onClick={()=>setTransMode('DRIVING')}><DirectionsCar /></Button>
                                                <Button disableRipple disableElevation variant={ transMode === 'BICYCLING' ? 'contained' : 'outlined'} onClick={()=>setTransMode('BICYCLING')}><DirectionsBikeOutlined /></Button>
                                                <Button disableRipple disableElevation variant={ transMode === 'TRAIN' ? 'contained' : 'outlined'} onClick={()=>setTransMode('TRAIN')}><DirectionsRailwayFilledOutlined /></Button>
                                                <Button disableRipple disableElevation variant={ transMode === 'WALKING' ? 'contained' : 'outlined'} onClick={()=>setTransMode('WALKING')}><DirectionsRun /></Button>
                                            </ButtonGroup>
                                            <Button onClick={() => calRoute(destinationRef.current.value, transMode)} disableElevation sx={{mt: '1rem', display: 'block', textTransform: 'none'}} variant='contained' color='secondary'>Calculate Commute</Button>
                                        </Box>
                                        :
                                        <Button disableRipple onClick={()=> setAddCommute(true)} disableElevation sx={{mt: '1rem', display: 'block', textTransform: 'none'}} variant='contained' color='secondary'>Add Commute</Button>
                                    }
                            </Box>

                            <Divider sx={{margin: '2rem 0', background: grey[100]}}/>

                            <Box>
                            {/* main features */}
                            <Typography variant='h5' sx={{fontWeight: 500, mt: '3rem'}}>Property Features</Typography>
                            <Typography variant='body2' color='textSecondary' mb={3}>Property details for {data?.address.full + ', ' + data?.address.city} </Typography>

                                <Table sx={{border: `1px solid ${grey[300]}`}}>
                                    {/* Listing Details */}
                                    <StyledTableHead >
                                        <TableRow>
                                        <TableCell>
                                            <TitleHead>Listing Details</TitleHead>
                                        </TableCell>
                                        </TableRow>
                                    </StyledTableHead>
                                    <TableBody>
                                        <TableRow>
                                            <Box sx={{p: '1rem'}}>
                                                <Grid container>
                                                    <Grid item sm={4}>
                                                        <ListItem disablePadding>
                                                            <StyledListIcon ><StyledIcon /></StyledListIcon>
                                                            <ListItemText>Listing Type: {_.startCase(data?.property?.subTypeText)}</ListItemText>
                                                        </ListItem>
                                                        <ListItem disablePadding>
                                                            <StyledListIcon ><StyledIcon /></StyledListIcon>
                                                            <ListItemText>Year Built: {data?.property?.yearBuilt}</ListItemText>
                                                        </ListItem>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                        <ListItem disablePadding>
                                                            <StyledListIcon><StyledIcon /></StyledListIcon>
                                                            <ListItemText>Structure Sq Ft: {data?.property?.area}</ListItemText>
                                                        </ListItem>
                                                        <ListItem disablePadding>
                                                            <StyledListIcon><StyledIcon /></StyledListIcon>
                                                            <ListItemText>Lot Acres: {data?.property?.acres}</ListItemText>
                                                        </ListItem>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                        <ListItem disablePadding>
                                                            <StyledListIcon><StyledIcon /></StyledListIcon>
                                                            <ListItemText>Lot Size Area: {data?.property?.lotSizeArea}</ListItemText>
                                                        </ListItem>
                                                        <ListItem disablePadding>
                                                            <StyledListIcon><StyledIcon /></StyledListIcon>
                                                            <ListItemText>Lot Description: {_.startCase(data?.property.lotDescription)}</ListItemText>
                                                        </ListItem>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </TableRow>
                                    </TableBody>

                                    {/* Interior Features */}
                                    <StyledTableHead>
                                        <TableRow>
                                        <TableCell>
                                            <TitleHead>Interior Features</TitleHead>
                                        </TableCell>
                                        </TableRow>
                                    </StyledTableHead>
                                    <TableBody>
                                        <TableRow>
                                            <Box sx={{p: '1rem'}}>
                                                <Grid container>
                                                    <Grid item sm={4}>
                                                    {
                                                        _.slice(interiorDetails, 0, 3).map(item => {
                                                            return (
                                                                <ListItem disablePadding key={item.key}>
                                                                    <StyledListIcon ><StyledIcon /></StyledListIcon>
                                                                    <ListItemText>{item.key}: { _.startCase(data?.property[item.value])}</ListItemText>
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                    {
                                                        _.slice(interiorDetails, 3, 6).map(item => {
                                                            return (
                                                                <ListItem disablePadding key={item.key}>
                                                                    <StyledListIcon ><StyledIcon /></StyledListIcon>
                                                                    <ListItemText>{item.key}: {_.startCase(data?.property[item.value])}</ListItemText>
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                    {
                                                        _.slice(interiorDetails, 6, interiorDetails.length).map(item => {
                                                            return (
                                                                <ListItem disablePadding key={item.key}>
                                                                    <StyledListIcon ><StyledIcon /></StyledListIcon>
                                                                    <ListItemText>{item.key}: {data?.property[item.value]}</ListItemText>
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </TableRow>
                                    </TableBody>

                                    {/* Exterior Features */}
                                    <StyledTableHead>
                                        <TableRow>
                                        <TableCell>
                                            <TitleHead>Exterior Features</TitleHead>
                                        </TableCell>
                                        </TableRow>
                                    </StyledTableHead>
                                    <TableBody>
                                        <TableRow>
                                            <Box sx={{p: '1rem'}}>
                                                <Grid container>
                                                    <Grid item sm={4}>
                                                    {
                                                        _.slice(exteriorDetails, 0, 3).map(item => {
                                                            return (
                                                                <ListItem disablePadding key={item.key}>
                                                                    <StyledListIcon ><StyledIcon /></StyledListIcon>
                                                                    <ListItemText>{item.key}: {_.startCase(data?.property[item.value])}</ListItemText>
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                    {
                                                        _.slice(exteriorDetails, 3, exteriorDetails.length).map(item => {
                                                            return (
                                                                <ListItem disablePadding key={item.key}>
                                                                    <StyledListIcon ><StyledIcon /></StyledListIcon>
                                                                    <ListItemText>{item.key}: {_.startCase(data?.property[item.value] ? data?.property[item.value] : 'No')}</ListItemText>
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </TableRow>
                                    </TableBody>

                                    {/* Parking/Garage Features */}
                                    <StyledTableHead >
                                        <TableRow>
                                        <TableCell>
                                            <TitleHead>Garage/Parking</TitleHead>
                                        </TableCell>
                                        </TableRow>
                                    </StyledTableHead>
                                    <TableBody>
                                        <TableRow>
                                            <Box sx={{p: '1rem'}}>
                                                <Grid container>
                                                    <Grid item sm={4}>
                                                    {
                                                        _.slice(garageDetails, 0, 3).map(item => {
                                                            if(item.key === 'Parking Features' || item.key === 'Parking Spaces'){
                                                                return (
                                                                    <ListItem disablePadding key={item.key}>
                                                                        <StyledListIcon ><StyledIcon /></StyledListIcon>
                                                                        <ListItemText>{item.key}: {_.startCase(data?.property.parking[item.value])}</ListItemText>
                                                                    </ListItem>
                                                                )
                                                            }else{
                                                                return (
                                                                    <ListItem disablePadding key={item.key}>
                                                                        <StyledListIcon ><StyledIcon /></StyledListIcon>
                                                                        <ListItemText>{item.key}: {_.startCase(data?.property[item.value])}</ListItemText>
                                                                    </ListItem>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    </Grid>
                                                
                                                    <Grid item sm={4}>
                                                    {
                                                        _.slice(garageDetails, 3, garageDetails.length).map(item => {
                                                            return (
                                                                <ListItem disablePadding key={item.key}>
                                                                    <StyledListIcon ><StyledIcon /></StyledListIcon>
                                                                    <ListItemText>{item.key}: {_.startCase(data?.property[item.value])}</ListItemText>
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                    </Grid>
                                                
                                                </Grid>
                                            </Box>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>

                            {/* Similar home near you */}
                            <Box mt={5}>
                                <Typography variant='h5' mb={1} sx={{fontWeight: 500}}>Similar Homes Near You</Typography>
                                <Typography variant='body2' color='textSecondary' mb={3}>Homes similar to {data?.address.full + ', ' + data?.address.city} are listed around ${data?.listPrice?.toLocaleString()}</Typography>
                               
                                <Box mb={'1.5rem'}>
                                        <Box id='map' sx={{height: '22rem', borderRadius: '15px', overflow: 'hidden', bgcolor: grey[200]}} >
                                            {
                                                isLoaded &&
                                                <GoogleMap 
                                                    mapTypeId='roadmap'
                                                    mapContainerStyle={{
                                                        height: "100%",
                                                        width: "100%"
                                                    }}
                                                    center={{ lat: data?.geo?.lat, lng: data?.geo?.lng }}
                                                    zoom={11.5}
                                                    clickableIcons={false}
                                                >
                                                {/* <Polygon paths={boundary} /> */}
                                                
                                                {
                                                    similar?.slice(0,6)?.map( (val,index) => {
                                                        return (
                                                            <Marker
                                                                key={index} 
                                                                position={{ lat: val?.geo?.lat, lng: val?.geo?.lng }}
                                                                label= {{ text: convertCurrencySystem(val?.listPrice), color: '#fff', fontSize: '12px'}}
                                                                icon={ listingID === `${val?.mlsId}` ? MarkedIcon : MarkerIcon }
                                                                visible={true}
                                                                onClick={()=> {
                                                                    setListingID(`${val?.mlsId}`)
                                                                    // markerToView(val?.mlsId)
                                                                }}
												            />
                                                        )
                                                    })
                                                }

                                                <Marker
                                                    position={{ lat: data?.geo?.lat, lng: data?.geo?.lng }}
                                                    // label= {{ text: convertCurrencySystem(data?.listPrice), color: '#fff', fontSize: '12px'}}
                                                    icon={ HouseMarker}
                                                    visible={true}
                                                    
                                                />
                                                </GoogleMap>
                                            }
                                        </Box>
                                </Box>
                                <Grid container spacing={2}>
                                        {
                                            path.startsWith('/open-houses') ?
                                            similar?.slice(0,6)?.map((item, index) => {
                                                return (
                                                    <Grid key={index} item xs={12} sm={6} lg={4}>
                                                        <ListingCard key={index}
                                                            inView={ listingID === `${item?.mlsId}` ? true : false}
                                                            border={'true'}
                                                            listDate={item?.listDate}
                                                            mlsId={item?.mlsId} 
                                                            status={item?.property.type === 'RES' ? 'For Sale' : 'For Rent'} 
                                                            address={_.startCase((typeof item.address.full === 'object' ? '' : (item.address.full + ', ')) + item?.address?.city + ', ' + item?.address?.state + item?.address?.postalCode)}  
                                                            price={item?.listPrice?.toLocaleString()} 
                                                            image={item?.photos[1] ? item?.photos[1] : item?.photos[0]} 
                                                            bed={item?.property.bedrooms} 
                                                            bath={item?.property.bathsFull}
                                                            area={item?.property.area}
                                                            vendor={item?.listing === 'mls' ? 'mls' : 'crmls'}
                                                            active={item?.mls?.status}
                                                        />
                                                    </Grid>
                                                )

                                            })
                                            :
                                            similar?.slice(0,6)?.map((item, index) => {
                                                return (
                                                    <Grid key={index} item xs={12} sm={6} lg={4}>
                                                        <ListingCard key={index}
                                                            inView={ listingID === `${item?.mlsId}` ? true : false}
                                                            border={'true'}
                                                            listDate={item?.listDate}
                                                            mlsId={item?.mlsId} 
                                                            address={_.startCase((typeof item.address.full === 'object' ? '' : (item.address.full + ', ')) + item?.address?.city + ', ' + item?.address?.state + item?.address?.postalCode)}  
                                                            price={item?.listPrice?.toLocaleString()} 
                                                            image={item?.photos[1] ? item?.photos[1] : item?.photos[0]} 
                                                            bed={item?.property.bedrooms} 
                                                            bath={item?.property.bathsFull}
                                                            area={item?.property.area}
                                                            vendor={item?.listing === 'mls' ? 'mls' : 'crmls'}
                                                            active={item?.mls?.status}
                                                        />
                                                    </Grid>
                                                )

                                            })
                                        }
                                </Grid>

                            </Box>

                            {/* Nearby New Homes */}
                            {
                                path.startsWith('/rental') ? null 
                                :
                                <Box mt={5}>
                                    {
                                        similar?.length > 0 ?
                                        <>
                                            <Typography variant='h5' mb={1} sx={{fontWeight: 500}}> New Selling Homes</Typography>
                                            <Typography variant='body2' color='textSecondary' mb={3}>New selling homes similar to {data?.address.full + ', ' + data?.address.city}</Typography>
                                            <Grid container spacing={2}>

                                                {
                                                        path.startsWith('/open-houses') ?
                                                        _.sampleSize(similar, 6)?.map((item, index) => {
                                                            return (
                                                                <Grid key={index} item xs={12} sm={6} lg={4}>
                                                                    <ListingCard 
                                                                            inView={ listingID === `${item?.mlsId}` ? true : false}
                                                                            id={`${item?.mlsId}`}
                                                                            listDate={item?.listDate}
                                                                            mlsId={item?.mlsId} 
                                                                            status={'openhouse'} 
                                                                            address={_.startCase((typeof item.address.full === 'object' ? '' : (item.address.full + ', ')) + item?.address.city + ', ' + item?.address.state + item?.address.postalCode)}  
                                                                            price={item?.listPrice?.toLocaleString()} 
                                                                            image={item?.photos[1] ? item?.photos[1] : item?.photos[0]} 
                                                                            bed={item?.property.bedrooms} 
                                                                            bath={item?.property.bathsFull}
                                                                            area={item?.property.area}
                                                                            vendor={item?.listing === 'mls' ? 'mls' : 'crmls'}
                                                                            startTime= {item?.listing === 'mls' ? item?.openHouse?.date : item?.openHouse?.startTime}
                                                                            endTime= {item?.listing === 'mls' ? item?.openHouse?.date : item?.openHouse?.endTime}
                                                                            active={item?.mls?.status}
                                                                        />
                                                                </Grid>
                                                                )

                                                        })
                                                        :
                                                        _.sampleSize(similar, 6)?.map((item, index) => {
                                                            return (
                                                                <Grid key={index} item xs={12} sm={6} lg={4}>
                                                                    <ListingCard 
                                                                        // inView={ listingID === `${item?.mlsId}` ? true : false}
                                                                        border={'true'}
                                                                        listDate={item?.listDate}
                                                                        mlsId={item?.mlsId} 
                                                                        address={_.startCase((typeof item.address.full === 'object' ? '' : (item.address.full + ', ')) + item?.address?.city + ', ' + item?.address?.state + item?.address?.postalCode)}  
                                                                        price={item?.listPrice?.toLocaleString()} 
                                                                        image={item?.photos[1] ? item?.photos[1] : item?.photos[0]} 
                                                                        bed={item?.property.bedrooms} 
                                                                        bath={item?.property.bathsFull}
                                                                        area={item?.property.area}
                                                                        vendor={item?.listing === 'mls' ? 'mls' : 'crmls'}
                                                                        active={item?.mls?.status}
                                                                    />
                                                                </Grid>
                                                            )
                                                        })

                                                }
                                                    
                                            </Grid>

                                        </>
                                        :
                                        <Box sx={{border: '1px dashed lightgrey', textAlign: 'center', p: '2rem', borderRadius: '10px'}}>
                                            <img src={NoProperty} width={'10%'} alt='no sold_listing' />
                                            <Typography variant='h6' mb={.5} mt={1} sx={{fontWeight: 500}}>No New Selling Homes</Typography>
                                            <Typography variant='body2' color='textSecondary' mb={3}>Selling homes near { _.startCase(data?.address.full + ', ' + data?.address.city)}</Typography>
                                        </Box>
                                    }
                                                    
                                </Box>
                            }
                            
                            {/* Nearby Sold Homes */}
                            <Box mt={5}>
                                
                                {
                                    soldListings?.length > 0 ?
                                    <>
                                        <Typography variant='h5' mb={1} sx={{fontWeight: 500}}>Nearby Sold Homes</Typography>
                                        <Typography variant='body2' color='textSecondary' mb={3}>Sold homes near { _.startCase(data?.address.full + ', ' + data?.address.city)}</Typography>
                                        <Grid container spacing={2}>

                                            {
                                                    path.startsWith('/open-houses') ?
                                                    _.sampleSize(soldListings, 6)?.map((item, index) => {
                                                        return (
                                                            <Grid key={index} item xs={12} sm={6} lg={4}>
                                                                <ListingCard 
                                                                        inView={ listingID === `${item?.mlsId}` ? true : false}
                                                                        id={`${item?.mlsId}`}
                                                                        listDate={item?.closeDate}
                                                                        mlsId={item?.mlsId} 
                                                                        status={'openhouse'} 
                                                                        address={_.startCase((typeof item.address.full === 'object' ? '' : (item.address.full + ', ')) + item?.address.city + ', ' + item?.address.state + item?.address.postalCode)}  
                                                                        price={item?.closePrice?.toLocaleString()} 
                                                                        image={item?.photos[1] ? item?.photos[1] : item?.photos[0]} 
                                                                        bed={item?.property.bedrooms} 
                                                                        bath={item?.property.bathsFull}
                                                                        area={item?.property.area}
                                                                        vendor={item?.listing === 'mls' ? 'mls' : 'crmls'}
                                                                        startTime= {item?.listing === 'mls' ? item?.openHouse?.date : item?.openHouse?.startTime}
                                                                        endTime= {item?.listing === 'mls' ? item?.openHouse?.date : item?.openHouse?.endTime}
                                                                        active={item?.mls?.status}
                                                                        office={item?.office}
                                                                    />
                                                            </Grid>
                                                            )

                                                    })
                                                    :
                                                    _.sampleSize(soldListings, 6)?.map((item, index) => {
                                                        return (
                                                            <Grid key={index} item xs={12} sm={6} lg={4}>
                                                                <ListingCard 
                                                                    // inView={ listingID === `${item?.mlsId}` ? true : false}
                                                                    border={'true'}
                                                                    listDate={item?.closeDate}
                                                                    mlsId={item?.mlsId}
                                                                    status={'soldhomes'} 
                                                                    address={_.startCase((typeof item.address.full === 'object' ? '' : (item.address.full + ', ')) + item?.address?.city + ', ' + item?.address?.state + item?.address?.postalCode)}  
                                                                    price={item?.closePrice?.toLocaleString()} 
                                                                    image={item?.photos[1] ? item?.photos[1] : item?.photos[0]} 
                                                                    bed={item?.property.bedrooms} 
                                                                    bath={item?.property.bathsFull}
                                                                    area={item?.property.area}
                                                                    vendor={item?.listing === 'mls' ? 'mls' : 'crmls'}
                                                                    active={item?.mls?.status}
                                                                    office={item?.office}
                                                                />
                                                            </Grid>
                                                        )
                                                    })

                                            }
                                                
                                        </Grid>
                                    </>
                                    :
                                    <Box sx={{border: '1px dashed lightgrey', textAlign: 'center', p: '2rem', borderRadius: '10px'}}>
                                        <img src={NoProperty} width={'10%'} alt='no sold_listing' />
                                        <Typography variant='h6' mb={.5} mt={1} sx={{fontWeight: 500}}>No Sold Homes</Typography>
                                        <Typography variant='body2' color='textSecondary' mb={3}>Sold homes near { _.startCase(data?.address.full + ', ' + data?.address.city)}</Typography>
                                    </Box>
                                }
                                                
                            </Box>

                        </Grid>
                        <Hidden mdDown>
                            <Grid item sm={3} md={4} lg={3}>
                                <Hidden smDown>
                                    <FloatingBar disable={ path?.startsWith('/sold-homes') ? true : false} city={data?.address?.city} position={'sticky'} top={'5%'} startRequest={startRequest} />
                                </Hidden>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Box>
            </Container> 
             
        }

        {/* PREVIEW DIALOGS */}
        <Dialog open={open} onClose={()=> setOpen(false)} fullWidth maxWidth='xl'>
            <DialogContent>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Photos" value="1" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{padding: '2rem 0 0 0'}}>
                            <Grid container spacing={3}>
                                <Grid item sm={3}>
                                    <Box sx={{overflowY: 'scroll', height: previewHeight}}>
                                        <ImageList variant='quilted' id='imgList' cols={1} gap={10} sx={{margin: 0}}>
                                            {
                                                data?.photos?.map((img, index) => {
                                                    return (
                                                        <ImageListItem key={index} onClick={()=>setStartPosition(index)} sx={{cursor: 'pointer'}}>
                                                            <img src={`${img}?fit=crop&auto=format`} style={{borderRadius: '6px'}} alt={`property_img_${index}`} loading='lazy'/>
                                                        </ImageListItem>
                                                    )
                                                })
                                            }
                                            
                                        </ImageList>
                                    </Box>
                                </Grid>
                                <Grid item sm={9}>
                                    <PropertySlider
                                        items={1} autoplay={true} autoplaySpeed={500} margin={20} loop={true} lazyLoad={true} height={'100%'} startPosition={startPosition}
                                        nav={true} navElement="div" navText={[`<i class='fas fa-arrow-left'></i>`, `<i class='fas fa-arrow-right'></i>`]}
                                        responsive={ { 1400 : {items : '1'}, 1200 : {items : '1'}, 760 : {items : '1'}, 340 : {items : '1'} } }
                                    > 
                                        {
                                            data?.photos?.map((item, index) => {
                                            return (
                                                <Box key={index}  sx={{ 
                                                    backgroundImage: `url(${item})`, 
                                                    backgroundSize: 'cover', 
                                                    backgroundPosition: 'center', 
                                                    width: '100%', height: previewHeight,
                                                    borderRadius: '10px'
                                                    }} 
                                            
                                                />
                                                )

                                            })
                                        }
                                    </PropertySlider>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        
                    </TabContext>
                </Box>
            </DialogContent>
        </Dialog>

        {/* % Commission */}
        <Dialog open={show} onClose={()=> setShow(false)}>
            <DialogContent sx={{padding: '3rem'}}>
                <Typography variant='h6' sx={{fontWeight: 500}}>
                Buy this home with a BPO Homes Agent or Partner Agent and receive a ${(data?.listPrice / 100)?.toLocaleString()} commission credit</Typography>
                <Typography variant='body1' mt={1} mb={3} color='textPrimary'>See the breakdown of savings when you buy with a BPO Homes Agent or Partner Agent.</Typography>
                
                <Box p={'1rem'} sx={{border: `1px solid ${grey[300]}`, borderRadius: '10px'}}>
                    <Typography sx={{fontWeight: 600}} mb={3}>{data?.address.full}</Typography>  
                    <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px 0'}}>
                        <Typography color='textSecondary'>If sold at list price</Typography>
                        <Typography sx={{fontWeight: 600}}>${data?.listPrice?.toLocaleString()}</Typography>
                    </span>
                    <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Button onClick={()=>setCommission(!commission)} variant='text' color='secondary' sx={{textTransform: 'none', padding: 0, fontSize: '1.1rem'}} endIcon={<KeyboardArrowRight fontSize='small' />}>Estimated Buyer Agent's Commission</Button>
                        <Typography sx={{fontWeight: 600}}>2.5%</Typography>
                    </span> 
                    {  commission &&
                        <Typography mt={1} sx={{fontSize: '.8rem'}} color='textPrimary'>
                        The seller will typically pay 2.5% of the final sale price to the brokerage representing the buyer. If this home sold for its list price of ${data?.listPrice?.toLocaleString()}, the buyer's agent would receive ${(data?.listPrice * 0.025)?.toLocaleString()}

                        </Typography>
                    }
                    <Divider sx={{bgcolor: grey[200], my:'1rem'}} />  
                    <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px 0'}}>
                        <Typography>Buyer Agent receives</Typography>
                        <Typography sx={{fontWeight: 600}}>${(data?.listPrice * 0.025)?.toLocaleString()}</Typography>
                    </span>  

                    <Box sx={{padding: '1rem', bgcolor: grey[200], borderRadius: '8px'}}>
                        <Typography sx={{fontWeight: 600}} mb={1}>Buy with a BPO Agent</Typography>
                        <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Button variant='text' color='secondary' sx={{textTransform: 'none', padding: 0, fontSize: '1.1rem'}}> Refunded to you</Button>
                            <Typography sx={{fontWeight: 600}}>${(data?.listPrice * 0.01)?.toLocaleString()}</Typography>
                        </span>  
                        <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px 0'}}>
                            <Typography>Price after refund</Typography>
                            <Typography sx={{fontWeight: 600}}>${(data?.listPrice - (data?.listPrice * 0.01))?.toLocaleString()}</Typography>
                        </span>  
                    </Box>        
                </Box>
            </DialogContent>
        </Dialog>

        {/* Share Button */}
        <Dialog open={share} maxWidth='xs'>
            <DialogContent sx={{position:'relative', padding: '2rem'}}>
                <IconButton onClick={closeShare} sx={{position: 'absolute', top: '5%', right: '5%'}}><Close fontSize='small' /></IconButton>
                <Typography mb={3} variant='h6'>Share Listing</Typography>
                {
                    emailIndex.map((el, index) => {
                        return <TextField key={index} size='small' value={index === 0 ? currEmail : index === 1 ? email1 : index === 2 ? email2 : index === 3 ? email3 : index === 4 ? email4 : ''} 
                                onChange={(e)=>updateEmails(e, index)}
                                fullWidth placeholder='Email address' InputProps={{
                                    endAdornment: <InputAdornment position='end'>
                                        {index === 0 ? <IconButton onClick={addEmails}><Add fontSize='small' /></IconButton> : 
                                        <IconButton onClick={()=>removeEmail(index)}><Close fontSize='small' /></IconButton> }
                                    </InputAdornment>
                                    }} sx={{mb: '1rem'}} 
                                />
                    })
                }
                <TextField sx={{mb: '1rem'}} value={note} onChange={(e)=> setNote(e.target.value)} multiline rows={5} placeholder='Enter your note' fullWidth />
                <LoadingButton loading={shareLoad} variant='contained' size='large' onClick={shareListing} disableElevation sx={{textTransform: 'none'}} fullWidth color='secondary' >Send</LoadingButton>
                <Divider sx={{my: '1rem'}} />
                <Box textAlign={'center'} sx={{display: 'flex', gap: '1rem', justifyContent: 'center'}}>
                    <FacebookShareButton url={pageUrl}><FacebookIcon style={{borderRadius: '8px'}} size={40} /></FacebookShareButton>
                    <LinkedinShareButton url={pageUrl}><LinkedinIcon style={{borderRadius: '8px'}} size={40} /> </LinkedinShareButton>
                    <TwitterShareButton url={pageUrl}><TwitterIcon style={{borderRadius: '8px'}} size={40} /></TwitterShareButton>
                    <WhatsappShareButton url={pageUrl}><WhatsappIcon style={{borderRadius: '8px'}} size={40} /></WhatsappShareButton>
                </Box>
            </DialogContent>
        </Dialog>

          {/* Schedule a Tour */}
        <Dialog open={open2} maxWidth='sm' >
            <Box sx={{ position: 'relative'}}>
                <CardMedia component="img" height="250" image={data?.photos[1] ? data?.photos[1] : data?.photos[0]} alt={data?.address.full} />
                <IconButton sx={{position: 'absolute', right: '3%', top: '5%', bgcolor: alpha('#000', .3)}} onClick={onHandleClose}><Close fontSize='small' color='paper' /></IconButton>
            </Box>
            <Divider sx={{bgcolor: grey[300]}} />
            <DialogContent sx={{padding: '2.5rem'}}>
            <Typography textAlign={'center'} variant='h6' >Schedule a Tour</Typography>
            <Typography textAlign={'center'} mb={3} variant='body2' noWrap>{ data?.address.full + ', ' + data?.address.city + ', ' + data?.address.state + ' ' + data?.address.postalCode} </Typography>

                {
                    page === 0 && 
                    <>
                        <ButtonGroup size='large' sx={{mb: 4}} variant='outlined' color='secondary' fullWidth>
                            <Button variant={formInput.meetingType === 'In Person' ? 'contained' : 'outlined'} onClick={()=> dispatchForm({type: 'TYPE', payload: 'In Person'})}
                             sx={{textTransform: 'none'}} disableElevation>In Person</Button>
                            <Button variant={formInput.meetingType === 'On Video' ? 'contained' : 'outlined'} onClick={()=> dispatchForm({type: 'TYPE', payload: 'On Video'})} 
                            disableElevation sx={{textTransform: 'none'}}>On Video</Button>
                        </ButtonGroup>
                        <Typography mb={1} sx={{fontWeight: 500}}>Pick a date</Typography>
                        <Box sx={{width: '100%', overflowX: 'scroll'}}>
                            <Box sx={{display: 'flex', gap: '1rem'}}>
                                {
                                    dates?.map((date, index) => {
                                        const newDate = date.split(' ')
                                        return (
                                            <Box onClick={()=> dispatchForm({type: 'DATE', payload: date})} key={index} 
                                            sx={{
                                                flex: 1,
                                                textAlign: 'center',
                                                borderRadius: '10px',
                                                cursor: 'pointer',
                                                bgcolor: formInput.meetingDate === date ? 'secondary.main' : null,
                                                padding: '1rem 2.8rem', 
                                                border: '1px solid lightgrey'}}>
                                                <Typography variant='body2' sx={{color: formInput.meetingDate === date && '#fff'}}>{newDate[0]}</Typography>
                                                <Typography sx={{fontSize: '2rem', color: formInput.meetingDate === date ? '#fff' : grey[400]}}>{newDate[2]}</Typography>
                                                <Typography variant='body2' sx={{color: formInput.meetingDate === date && '#fff'}}>{newDate[1].toUpperCase()}</Typography>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
						</Box>
                        {/* Select Time */}
                        <Typography my={1} sx={{fontWeight: 500}}>Pick a time</Typography>
                        <Box sx={{width: '100%', overflowX: 'scroll'}}>
                            <Box sx={{display: 'flex', gap: '1rem'}}>
                                {
                                    timeData.map((time, index) => {
                                        return (
                                            <Box onClick={()=> dispatchForm({type: 'TIME', payload: time})} key={index} 
                                            sx={{
                                                textAlign: 'center',
                                                borderRadius: '10px',
                                                cursor: 'pointer',
                                                bgcolor: formInput.meetingTime === time ? 'secondary.main' : null,
                                                padding: '1rem 2.8rem', 
                                                border: '1px solid lightgrey'}}>
                                                <Typography sx={{color: formInput.meetingTime === time && '#fff'}}>{time}</Typography>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
						</Box>
                
                        <Button onClick={onFormSubmit} sx={{mt: '2rem', textTransform: 'none'}} size='large' color='secondary' variant='contained' disableElevation fullWidth> Continue</Button>
                    </>
                }
                {
                    page === 1 &&
                    <>
                       <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <InputField variant='outlined' value={formInput.firstname} required
                            fullWidth label='First name' onChange={(e) => dispatchForm({type:'FIRSTNAME', payload: e.target.value})} 
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputField variant='outlined' value={formInput.lastname} required
                            onChange={(e) => dispatchForm({type:'LASTNAME', payload: e.target.value})} fullWidth label='Last name' />
                        </Grid>
                       </Grid>
                            <InputField variant='outlined' type={'email'} value={formInput.email} required
                            onChange={(e) => dispatchForm({type:'EMAIL', payload: e.target.value})} fullWidth label='Email address' />
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} sm={9}>
                                <InputField variant='outlined' type={'tel'} inputProps={{ min: 0, maxLength: '10' }} value={formInput.phone} required
                                 onChange={(e) => dispatchForm({type:'PHONE', payload: e.target.value })}
                                 fullWidth label='Phone number' />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <InputField variant='outlined' type={'number'} value={formInput.phoneExt} 
                                 onChange={(e) => dispatchForm({type:'EXT', payload: e.target.value })}
                                 fullWidth label='Ext' />
                            </Grid>
                        </Grid>
                        <InputField variant='outlined' multiline rows={4} value={formInput.otherInfo} required
                         onChange={(e) => dispatchForm({type:'OTHER_INFO', payload: e.target.value})}
                         fullWidth placeholder='Notes' />

                        <Grid container columnSpacing={2}>
                            <Grid item xs={5} sm={6}>
                                <Button onClick={stepBack} disabled={disable} size='large' color='secondary' sx={{textTransform :'none'}}  variant='outlined' disableElevation fullWidth> Back</Button>
                            </Grid>
                            <Grid item xs={7} sm={6}>
                                <LoadingButton loading={loadBtn} onClick={onFormSubmit}  disabled={disable} sx={{textTransform :'none'}} size='large' color='secondary' variant='contained' disableElevation fullWidth> Request Tour</LoadingButton>
                            </Grid>
                        </Grid>
                    </>
                }
            </DialogContent>
        </Dialog>

        {/* Response Dialog */}
        <Dialog open={response.open}>
            <DialogContent sx={{padding: '2.5rem', textAlign: 'center'}}>
                { response.status === 'success' ? <CheckCircle color='primary' fontSize='large' /> : <Error fontSize='large' sx={{ color: 'red'}} /> }
                {
                    response.status === 'success' ?
                    <>
                        <Typography variant='h6'>Schedule successful</Typography>
                        <Typography>Our Agents will get in touch with you shortly. Thank you for scheduling a Tour with BPO Homes</Typography>
                        <Button variant='contained' disableElevation color='secondary' onClick={onHandleClose} 
                         sx={{textTransform: 'none', mt: '1rem'}}>Please wait...</Button>
                    </> 
                    :
                    <>
                        <Typography variant='h6'>Schedule Failed</Typography>
                        <Typography>Sorry, something went wrong. Please try again.</Typography>
                        <Button variant='contained' disableElevation color='secondary' 
                            onClick={() => setResponse({open: false, status: null})} 
                            sx={{textTransform: 'none', mt: '1rem'}}>
                            Try Again
                        </Button>
                    </> 
                }
            </DialogContent>
        </Dialog>

    </div>
  )
}

const mapStateToprops = (state) => {
    return state
}

export default connect(mapStateToprops, {addToFavorite, removeFavorite})(PropertyDetails)