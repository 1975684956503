import { Button, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography, Collapse } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import Logo from '../assets/logo-wide.png'
import { Business, HomeWork, Close, Handshake, House, PeopleAlt, KeyboardArrowRight, ExpandMore } from '@mui/icons-material'
import { grey } from '@mui/material/colors'
import { useDispatch } from 'react-redux'

const drawer = 300

const useStlyle = makeStyles(theme => ({
    sidebar: {
        display: 'flex',
        flexDirection: 'column'
    },
    wrap: {
        padding: '1rem',
        [theme.breakpoints.down('md')]: {
            padding: '1.5rem',
        }
    },
    drawerPaper: {
        width: drawer,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    logo: {
        width: '50%',
        [theme.breakpoints.up('sm')]: {
            width: '70%'
        }
    },
    btn: {
        textTransform: 'none !important',
        marginBottom: '10px !important',
        height: '3rem',
        borderRadius: '50px'
    },
    iconBtn: {
        borderRadius: '4px !important',
        height: 'fit-content',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            padding: '0.25rem !important'
        }
    }
}))

const SideBar = (props) => {
    const classes = useStlyle()
    const [openBuy, setOpenBuy] = useState(false)
    const [openSell, setOpenSell] = useState(false)
    const [openAgent, setOpenAgent] = useState(false)
    const dispatch = useDispatch()


  return (
    <Box>
        <Drawer anchor='left' className={classes.sidebar} classes={{ paper: classes.drawerPaper }}  open={props.open} onClose={() => props.toggle()} >
            <Box className={classes.wrap} display={'flex'} justifyContent='space-between' alignItems={'center'}>
                <img src={Logo} className={classes.logo} alt='logo' />   
                <IconButton className={classes.iconBtn} onClick={() => props.toggle()}><Close fontSize='small' /></IconButton>
               
            </Box>
            <Divider />
            <Box className={classes.wrap}>
                <List disablePadding>

                    {/* Buy */}
                    <ListItem onClick={()=> setOpenBuy(!openBuy)} button>
                        <ListItemIcon><HomeWork /></ListItemIcon>
                        <ListItemText> <Typography variant='body1'>Buy</Typography> </ListItemText>
                        { openBuy ? <ExpandMore /> : <KeyboardArrowRight sx={{color: grey[400]}} /> }
                    </ListItem>
                    <Collapse in={openBuy}>
                        <List>
                            <ListItem component='a' href={'/homes-for-sale'} button>
                                <ListItemText> <Typography variant='body2'>Homes For Sale</Typography> </ListItemText>
                            </ListItem>
                            <ListItem component='a' href={'/partners'} button>
                                <ListItemText> <Typography variant='body2'>Buy With Partner Agent</Typography> </ListItemText>
                            </ListItem>
                            <ListItem component='a' href={'/agents'} button>
                                <ListItemText> <Typography variant='body2'>Find A Buyer Agent</Typography> </ListItemText>
                            </ListItem>
                            <ListItem component='a' href={'/buyer-checklist'} button>
                                <ListItemText> <Typography variant='body2'>Buyer's Checklist</Typography> </ListItemText>
                            </ListItem>
                            <ListItem component='a' href={'/buyer-firsttimer'} button>
                                <ListItemText> <Typography variant='body2'>First Timer</Typography> </ListItemText>
                            </ListItem>
                            <ListItem component='a' href={'/join'} button>
                                <ListItemText> <Typography variant='body2'>Join BPO Realty</Typography> </ListItemText>
                            </ListItem>
                            
                        </List>
                    </Collapse>

                    {/* Rent */}
                    <ListItem component='a' href={'/homes-for-rent'} button>
                        <ListItemIcon><House /></ListItemIcon>
                        <ListItemText> <Typography variant='body1'>Rent</Typography> </ListItemText>
                    </ListItem>

                    {/* Sell */}
                    <ListItem onClick={()=> setOpenSell(!openSell)} button>
                        <ListItemIcon><Business /></ListItemIcon>
                        <ListItemText> <Typography variant='body1'>Sell</Typography> </ListItemText>
                        { openSell ? <ExpandMore /> : <KeyboardArrowRight sx={{color: grey[400]}} /> }
                    </ListItem>
                    <Collapse in={openSell}>
                        <List>
                            <ListItem component='a' href={'/partners'} button>
                                <ListItemText> <Typography variant='body2'>Sell with a BPO Homes Agent</Typography> </ListItemText>
                            </ListItem>
                            <ListItem component='a' href={'/agents'} button>
                                <ListItemText> <Typography variant='body2'>Find a Listing Agent</Typography> </ListItemText>
                            </ListItem>
                            <ListItem component='a' href={'/sellers-guide'} button>
                                <ListItemText> <Typography variant='body2'>Seller's Guide</Typography> </ListItemText>
                            </ListItem>
                            <ListItem component='a' href={'/home-improvement'} button>
                                <ListItemText> <Typography variant='body2'>Home Improvement Trends</Typography> </ListItemText>
                            </ListItem>
                            <ListItem component='a' href={'/explore'} button>
                                <ListItemText> <Typography variant='body2'>Explore My Options</Typography> </ListItemText>
                            </ListItem>
                            <ListItem component='a' href={'/join'} button>
                                <ListItemText> <Typography variant='body2'>Join BPO Realty</Typography> </ListItemText>
                            </ListItem>
                            <ListItem component='a' href={'/realty-agents'} button>
                                <ListItemText> <Typography variant='body2'>BPO Agent Offices</Typography> </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>

                    {/* Agent */}
                    <ListItem button onClick={()=> setOpenAgent(!openAgent)}>
                        <ListItemIcon><PeopleAlt /></ListItemIcon>
                        <ListItemText> <Typography variant='body1'>Agent</Typography> </ListItemText>
                        { openAgent ? <ExpandMore /> : <KeyboardArrowRight sx={{color: grey[400]}} /> }
                    </ListItem>
                    <Collapse in={openAgent}>
                        <List>
                            <ListItem component='a' href={'/agents'} button>
                                <ListItemText> <Typography variant='body2'>Find an Agent</Typography> </ListItemText>
                            </ListItem>
                            <ListItem component='a' href={'/join'} button>
                                <ListItemText> <Typography variant='body2'>Join as an Agent</Typography> </ListItemText>
                            </ListItem>
                            <ListItem component='a' href={'/realty-agents'} button>
                                <ListItemText> <Typography variant='body2'>BPO Realty Agents</Typography> </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>

                    {/* Partners */}
                    <ListItem component='a' href={'/partners'} button>
                        <ListItemIcon><Handshake /></ListItemIcon>
                        <ListItemText> <Typography variant='body1'>Partners</Typography> </ListItemText>
                    </ListItem>
                       
                </List>
            </Box>
            <Divider />
            <Box className={classes.wrap} style={{marginTop: 'auto', textAlign: 'center'}}>
                <Button className={classes.btn} sx={{borderRadius: '50px'}} onClick={()=>{
                    props.toggle()
                    dispatch({type: 'AUTH_LOGIN'})
                }}
                variant='contained' color='secondary' disableElevation fullWidth>Register/Login</Button>
                {/* <Button className={classes.btn} variant='contained' disableElevation style={{background: '#ffaa013d'}} fullWidth>Signup</Button> */}
                <Typography variant='body2' color={'textSecondary'} style={{fontSize: '0.7rem', marginTop: '1rem',}}>version 1.0.0</Typography>
            </Box>
            
        </Drawer>
    </Box>
  )
}

export default SideBar