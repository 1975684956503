import { createTheme } from "@mui/material/styles";


export const theme = createTheme({
    typography: {
        fontFamily: 'Roboto',
        fontSize: 15,
        fontWeightRegular: 300,
        h6: {
            fontWeight: 500
        }
    },
    palette: {
        primary: {
            main: '#FFAA01'
        },
        secondary: {
            main: '#1378A5'
        },
        text:{
            main: '#000'
        },
        paper: {
            main: '#fff'
        }
    },
    primaryColor: '#FFAA01',
    secondaryColor: '#1378A5'
})