import { Box, InputAdornment, TextField, Typography,styled, ListItem, ListItemText, ListItemIcon, Divider } from '@mui/material'
import { Search, Place, Business, Restore } from '@mui/icons-material'
import React from 'react'
import BGImage from '../assets/header_background.svg'
import { LoadingButton } from '@mui/lab'
import { grey } from '@mui/material/colors'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { listingURL } from '../api/apis'


const InputField = styled(TextField)(({theme})=> ({
    margin: '.7rem 0',
    
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
        background: '#fff',
    }
}))

const SearchBox = styled(Box)(({theme}) => ({
	borderBottom: `0px solid ${grey[300]}`,
	display: 'block',
    
}))


const Banner = ({ load, clickAway, onChange, focus, value, searchFilter, results, searchFocus, searchTerm, type, recent }) => {
    const navigate = useNavigate()

    const allResults = results?.length !== 0 ? results : JSON.parse(localStorage.getItem('places'))

  return (
    <Box sx={{
        height: '55vh',
        backgroundImage: `linear-gradient(180deg, rgba(0,0,0, 50%), transparent), url(${BGImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent:'center',
        alignItems: 'center'

    }}>
        <Box sx={{ 
                width: {xs: '80%', sm: '80%', md: '60%', lg: '40rem'}, 
                padding: '3rem', textAlign:'center', 
                position: 'relative' 
            }}>
            <Typography variant='h4' component={'p'} sx={{fontWeight: '500', color: '#fff', fontSize: {xs: '1.5rem', sm: '1.8rem', md: '2rem', lg: '2.2rem'} }}>Home is where your story begins.<br />Find it, Own it.</Typography>
            <form onSubmit={searchFilter}>
                <InputField type={'search'} id='searchbox'
                    variant='outlined' sx={{zIndex: 10}}
                    placeholder='Enter an address, city, neighborhood or zip code' 
                    onBlur={clickAway}
                    onChange={onChange} onFocus={focus}
                    value={searchTerm}
                    fullWidth InputProps={{
                        endAdornment: <InputAdornment position='end'>
                        <LoadingButton variant='contained' color='secondary' onClick={searchFilter} disableElevation loading={load} sx={{ 
                            padding: '.7rem', 
                            borderRadius: '10px', 
                            marginRight: '-8px',
                            '&:hover': {
                                bgcolor: 'primary.main'
                            }
                            }}><Search color='paper' /></LoadingButton>
                    </InputAdornment>
                }} />
            </form>
            
            {
                allResults?.length > 0 && value.length > 0 && searchFocus ?
                <Box mt={'-18px'} bgcolor={'#fff'} sx={{ 
                    overflowY: 'scroll', position:'absolute', pt: '.5rem',
                    zIndex:9, height: 'fit-content', borderRadius: '0 0 8px 8px',
                    boxShadow: '0 2px 20px rgba(0,0,0,15%)',
                    width: {xs: '76%',sm: '86%', md: '86%', lg: '40rem'}
                    }}>
                    {
                        type === 'city' ?
                        <SearchBox >
                            <Typography variant='body2' textAlign={'left'} sx={{ml: '1rem', mt: '1rem', fontWeight: 500, color: grey[600], fontSize: '.8rem'}}>PLACES</Typography>
                            {
                                allResults?.slice(0,4)?.map((res, index) => {
                                    return (
                                        <ListItem onClick={ async(e) => {
                                            e.stopPropagation();
                                            await listingURL.post(`search-upload/`, {search: res?.address?.city || res?.name})
                                            navigate(`/homes-for-sale/${_.startCase(res?.address?.city || res?.name)?.toLowerCase()}`)
                                        }} button key={index} sx={{padding: '.3rem 1.5rem', alignItems: 'flex-start'}}>
                                        <ListItemIcon sx={{minWidth: '25px', pt: '.6rem'}}>{<Place color='secondary' sx={{fontSize: '1rem'}} />}</ListItemIcon>
                                        <ListItemText>
                                            { index === 0 ? 
                                                ( 
                                                    <>
                                                        <Typography>{res?.address ? (_.startCase(res?.address?.city) + ', ' + _.startCase(res?.address?.state)) : res?.name}</Typography> 
                                                        <Typography variant='body2' sx={{fontSize: '0.85rem'}} color={'GrayText'}>{res?.address ? (_.startCase(res?.address?.city) + ', ' + _.startCase(res?.address?.state) + ', USA') : res?.formatted_address}</Typography>
                                                    </>
                                                )
                                                :
                                                ( 
                                                    <>
                                                        <Typography>{res?.address ? (_.startCase(res?.address?.city)) : res?.name}</Typography>
                                                        <Typography variant='body2' color={'GrayText'}>{ res?.address ? (_.startCase(res.address?.crossStreet?.toLowerCase()) + ' ' + _.startCase(res.address?.city) + ', ' + _.startCase(res.address?.state) + ', USA') : res?.formatted_address}</Typography>
                                                    </>
                                                ) 
                                            }
                                        </ListItemText>
                                        </ListItem>
                                    )
                                })
                            }
                        </SearchBox>
                        :
                        <SearchBox >
                            <Typography variant='body2' textAlign={'left'} sx={{ml: '1rem', mt: '1rem', fontWeight: 500, color: grey[600], fontSize: '.8rem'}}>ADDRESSES</Typography>
                            {
                                allResults?.slice(0,4)?.map((res, index) => {
                                    return (
                                        <ListItem onClick={ async(e) => {
                                            e.stopPropagation();
                                            await listingURL.post(`search-upload/`, {search: res?.address?.full || res?.name})
                                            navigate(`/listing/${res?.mlsId}/details/${res?.listing === 'mls' ? 'mls' : 'crmls'}`);
                                        }} button key={index} sx={{padding: '.5rem 1.5rem', alignItems: 'flex-start'}}>
                                        <ListItemIcon sx={{minWidth: '25px', pt: '.6rem'}}>{<Business color='secondary' sx={{fontSize: '1rem'}} />}</ListItemIcon>
                                        <ListItemText>
                                                <Typography>{res?.address ? (_.startCase(res?.address?.full)): res?.name}</Typography>
                                                <Typography variant='body2' sx={{fontSize: '.9rem'}} color={'GrayText'}>{ res?.address ? (_.startCase(res?.address?.city) + ', ' + _.startCase(res?.address?.state) + ' ' + res?.address?.postalCode) : res?.formatted_address}</Typography>
                                        </ListItemText>
                                        </ListItem>
                                    )
                                })
                            }
                        </SearchBox>
                    }

                    {/* Recent Search */}
                    <Divider light />
                    <SearchBox sx={{padding: '0 1rem', mb: '1rem'}}>
                            <Typography variant='body2' textAlign={'left'} sx={{ml: '1rem', mt: '1rem', mb: '1rem', fontWeight: 500, color: grey[600], fontSize: '.8rem'}}>RECENT SEARCH</Typography>
                            {
                                recent?.slice(0,2)?.map((res, index) => {
                                    return (
                                        <ListItem onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(`/homes-for-sale/${res?.search}`);
                                        }} button key={index} sx={{padding: '.5rem 1.5rem', alignItems: 'flex-start'}}>
                                        <ListItemIcon sx={{minWidth: '25px', pt: '.4rem'}}>{<Restore color='secondary' sx={{fontSize: '1.1rem'}} />}</ListItemIcon>
                                        <ListItemText>
                                            <Typography variant='body2'>{_.startCase(res?.search)}</Typography>
                                            {/* <Typography variant='body2' sx={{fontSize: '.9rem'}} color={'GrayText'}>{_.startCase(res?.address?.city) + ', ' + _.startCase(res?.address?.state) + ' ' + res?.address?.postalCode}</Typography> */}
                                        </ListItemText>
                                        </ListItem>
                                    )
                                })
                            }
                        </SearchBox>
                </Box>
                : allResults?.length === 0 && searchFocus ?
                <Box mt={'-10px'} bgcolor={'#fff'} sx={{ 
                    overflowY: 'scroll', position:'absolute',
                    zIndex:99, height: 'auto', borderRadius: '0 0 8px 8px',
                    boxShadow: '0 2px 20px rgba(0,0,0,15%)',
                    width: {xs: '76%',sm: '86%', md: '86%', lg: '86%'}
                    }}>
                        <SearchBox sx={{padding: '1rem'}}>
                            <Typography textAlign={'center'}>No listing found</Typography>
                        </SearchBox>
                        <SearchBox sx={{padding: '1rem', mb: '1rem'}}>
                            <Typography variant='body2' textAlign={'left'} sx={{ml: '1rem', mt: '1rem', fontWeight: 500, color: grey[600], fontSize: '.8rem'}}>RECENT SEARCH</Typography>
                            {
                                recent?.slice(0,2)?.map((res, index) => {
                                    return (
                                        <ListItem onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(`/homes-for-sale/${res?.search}`);
                                        }} button key={index} sx={{padding: '.5rem 1.5rem', alignItems: 'flex-start'}}>
                                        <ListItemIcon sx={{minWidth: '25px', pt: '.4rem'}}>{<Restore color='secondary' sx={{fontSize: '1.1rem'}} />}</ListItemIcon>
                                        <ListItemText>
                                            <Typography variant='body2'>{_.startCase(res?.search)}</Typography>
                                            {/* <Typography variant='body2' sx={{fontSize: '.9rem'}} color={'GrayText'}>{_.startCase(res?.address?.city) + ', ' + _.startCase(res?.address?.state) + ' ' + res?.address?.postalCode}</Typography> */}
                                        </ListItemText>
                                        </ListItem>
                                    )
                                })
                            }
                        </SearchBox>
                </Box>
                :
                null

            }
        </Box>
    </Box>
  )
}

export default Banner