import './App.css'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { connect } from 'react-redux'
import { ThemeProvider } from "@mui/material";
import { theme } from './theme'
import { Routes, Route } from 'react-router-dom'
import Homepage from "./layout/Homepage";
import PublicRoute from "./constants/PublicRoute";
import Buy from './layout/Buy';
import PropertyDetails from './layout/PropertyDetails';
import PartnerPremier from './layout/PartnerPremier/PartnerPremier'
import AboutPartner from './layout/PartnerPremier/AboutPartner'
import BuyerChecklist from './layout/Resources/BuyerChecklist'
import BuyerFirstTime from './layout/Resources/BuyerFirstTime'
import BuyerScore from './layout/Resources/BuyerScore'
import BPOGuide from './layout/Resources/BPOGuide'
import SellerGuide from './layout/Resources/SellerGuide'
import HomeImprovement from './layout/Resources/HomeImprovement'
import Explore from './layout/Resources/Explore'
import JoinBPO from './layout/JoinBPO/JoinBPO'
import BPOSignup from './layout/JoinBPO/BPOSignup'
import Sell from './layout/Sell';
import Rent from './layout/Rent';
import AgentFinder from './layout/AgentFinder';
import SingleAgentFinder from './layout/SingleAgentFinder';
import Modal from './components/Modal';
import Errorpage from './layout/ErrorPage';
import PrivacyPolicy from './layout/PrivacyPolicy';
import TermsOfUse from './layout/TermsOfUse';
import About from './layout/About';
import PaymentSuccess from './layout/PaymentSuccess';
import AuthLoader from './components/AuthLoader';
import ResetPassword from './layout/ResetPassword';
import { useState } from 'react';
import OpenHouses from './layout/OpenHouses';
import SEO from './components/SEO';
import Disclaimer from './layout/Disclaimers';
import RealtyAgentSearch from './layout/RealtyAgentSearch';
import HomeInspection from './layout/Resources/HomeInspection';
import ChoosingTheRightHome from './layout/Resources/ChoosingTheRightHome';
import NewRoofBenefits from './layout/Resources/NewRoofBenefits';
import KitchenRemodel from './layout/Resources/KitchenRemodel';
import MaximizingSpace from './layout/Resources/MaximizingSpace';
import Blog from './layout/Blog';
import CreatingThePerfectDesign from './layout/Resources/CreatingThePerfectDesign';
import JuniorMasterBedroom from './layout/Resources/JuniorMasterBedroom';
import DoublePaneWindows from './layout/Resources/DoublePaneWindows';
import FloorOptions from './layout/Resources/FloorOptions';
import PlumbingRealEstate from './layout/Resources/PlumbingRealEstate';
import PlumbingFixtures from './layout/Resources/PlumbingFixtures';
import Faucets from './layout/Resources/Faucet';
import FaucetBenefits from './layout/Resources/FaucetBenefits';
import Investors from './layout/Resources/Investors';
import SoldHomes from './layout/SoldHomes';
import InvestorList from './layout/investorList';



const App = (props) => {
  const [show, setShow] = useState(true) 

  const hideFooter = () => {
    setShow(false)
  }

  const showFooter = () => {
    setShow(true)
  }

  return (
    <>
        <SEO>
          <meta
            name='description'
            data-react-helmet='true'
            content="Don't Underlist. Don't Overpay. Get A BPO Report Today. Your Local Expert Agents Ready To Give You A BPO Report Now!"
          />
				  <title>BPO HOMES</title>
			  </SEO>
      <ThemeProvider theme={theme}>
      {
        props.modal && <Modal />
      }
      {
        props.loader && <AuthLoader open={props.loader} />
      }
        <Routes>
          <Route element={<PublicRoute footer={show} />}>
          <Route path='/investors' element={<InvestorList />} />
              <Route path="/" element={<Homepage />} />
              <Route path="/sell" element={<Sell />} />
              <Route path="/homes-for-rent" element={<Rent hide={hideFooter} show={showFooter} />} />
              <Route exact path="/homes-for-sale" element={<Buy hide={hideFooter} show={showFooter} />} />
              <Route exact path="/homes-for-sale/:city" element={<Buy hide={hideFooter} show={showFooter} />} />
              <Route exact path="/sold-homes" element={<SoldHomes hide={hideFooter} show={showFooter} />} />
              <Route exact path="/open-houses" element={<OpenHouses hide={hideFooter} show={showFooter} />} />
              <Route exact path="/agents" element={<AgentFinder />} />
              <Route exact path="/agents/:id" element={<SingleAgentFinder />} />
              <Route exact path="/listing/:id/details/:vendor" element={<PropertyDetails />} />
              <Route exact path="/open-houses/:id/details/:vendor" element={<PropertyDetails />} />
              <Route exact path="/sold-homes/:id/details/:vendor" element={<PropertyDetails />} />
              <Route exact path="/rental/:id/details/:vendor" element={<PropertyDetails />} />
              <Route path='/blog'>
                <Route path='' element={<Blog />} />
                <Route path='floor-options' element={<FloorOptions />} />
                <Route path='faucets' element={<Faucets />} />
                <Route path='investors' element={<Investors />} />
                <Route path='quality-faucets' element={<FaucetBenefits />} />
                <Route path='plumbing-fixtures' element={<PlumbingFixtures />} />
                <Route path='plumbing-real-estate' element={<PlumbingRealEstate />} />
                <Route path='double-pane-windows' element={<DoublePaneWindows />} />
                <Route path='perfect-design' element={<CreatingThePerfectDesign />} />
                <Route path='junior-master-bedrooms' element={<JuniorMasterBedroom />} />
                <Route path='buyer-checklist' element={<BuyerChecklist />} />
                <Route path='buyer-firsttimer' element={<BuyerFirstTime />} />
                <Route path='buyer-score' element={<BuyerScore />} />
                <Route path='bpo-guide' element={<BPOGuide />} />
                <Route path='sellers-guide' element={<SellerGuide />} />
                <Route path='home-improvement' element={<HomeImprovement />} />
                <Route path='home-inspection' element={<HomeInspection />} />
                <Route path='choosing-the-right-home' element={<ChoosingTheRightHome />} />
                <Route path='benefits-of-a-new-roof' element={<NewRoofBenefits />} />
                <Route path='kitchen-remodeling' element={<KitchenRemodel />} />
                <Route path='maximizing-space' element={<MaximizingSpace />} />
                <Route path='explore' element={<Explore />} />
              </Route>
              <Route path='/join'>
                <Route path='' element={<JoinBPO />} />
                <Route path='signup' element={<BPOSignup />} />
              </Route>
              <Route path='/partners'>
                <Route path='' element={<PartnerPremier />} />
                <Route path='benefits' element={<AboutPartner />} />
              </Route>
              <Route path='/reset_password/:id/:token' element={<ResetPassword />} />
              <Route path='/realty-agents' element={<RealtyAgentSearch />} />
              <Route path='/realty-agents/:search' element={<RealtyAgentSearch />} />
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route path='/terms-of-use' element={<TermsOfUse />} />
              <Route path='/disclaimers' element={<Disclaimer />} />
              <Route path='/about' element={<About />} />
              <Route path='/payment-success' element={<PaymentSuccess />} />


              <Route path='*' element={<Errorpage />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </>
  );
}


const mapStateToprops = (state) => {
  //console.log(state)
  return state
}


export default connect(mapStateToprops, {})(App)
